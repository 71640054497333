import EmptyStateImage from "../../assets/images/no-mixes.png";

import { Center, createStyles, Group, Image, LoadingOverlay, Space, Spoiler, Stack, Table, Text } from "@mantine/core";
import { IconInfoCircle, IconUser } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonIcon from "../../components/_phosphorus/buttons/ButtonIcon";
import LabelButton from "../../components/inputs/buttons/LabelButton";
import Header from "../../components/layout/Header";
import MixNavbar, { MixPage } from "../../components/layout/nav/MixNavbar";
import PageContainer from "../../components/layout/PageContainer";
import Title from "../../components/text/Title";
import { RoomContext } from "../../func/contexts/roomContext";
import { checkMix } from "../../func/models/mix";
import WebsocketMain from "../../func/websockets/websocketMain";
import { findRoomByHostID } from "../../func/models/room";
import Modal from "../../components/layout/modals/Modal";
import { ClientSession } from "../../func/models/sessions/clientSession";
import ShareModal from "../../components/layout/modals/ShareModal";
import PWAInstallPrompt from "../../components/display/PWAInstallPrompt";
import { ComponentSize } from "../../components/_phosphorus/_types";
import Button from "../../components/_phosphorus/buttons/Button";
import InvertableImage from "../../components/display/InvertableImage";
import QueueItem from "../../components/display/spotify/QueueItem";

const useClasses = createStyles((theme) => ({
  buttonHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    paddingTop: theme.spacing.md,
    paddingBottom: theme.spacing.md,
    backgroundColor: theme.white,
    gap: theme.spacing.xs,
  },
  spotifyLogo: {
    filter: theme.colorScheme === 'light' ? `grayscale(1)` : `invert(100%) grayscale(1)`,
    cursor: 'pointer',
  },
}));

export default function MixHomePage() {

  /** Contexts */
  const roomContext = useContext(RoomContext);

  /** Hooks */
  const { t } = useTranslation('mixHome');
  const { classes } = useClasses();
  const navigate = useNavigate();

  /** States */
  const [loading, setLoading] = useState(true);
  const [playlistID, setPlaylistID] = useState('');
  const [isHost, setIsHost] = useState(false);

  const [guestModalOpened, setGuestModalOpened] = useState(false);
  const [infoModalOpened, setInfoModalOpened] = useState(false);
  const [shareModalOpened, setShareModalOpened] = useState(false);

  /** Attempt to get the playlist's ID */
  async function getPlaylistID() {
    if (!roomContext.party.host) return;
    let room = await findRoomByHostID(roomContext.party.host.id);
    if (!room) return;
    setPlaylistID(room.playlistID);
  }

  /** Stop loading the page when the roomInfo updates */
  useEffect(() => {
    if (!checkMix(roomContext, navigate)) return;
    if (roomContext.party.id) setLoading(false);

    getPlaylistID();
  }, [roomContext]);

  useEffect(() => {
    let clientSession = new ClientSession();
    clientSession.deserialise();
    setIsHost(clientSession.isHost);
  }, []);

  return (
    <>
      <WebsocketMain />

      {/* Guest list modal */}
      <Modal
        opened={guestModalOpened}
        setOpened={setGuestModalOpened}
        title={t('modals.guests.title')}
        withCloseButton
      >
        {roomContext.party.guests.length === 0 ?
          <Text align='center'>
            {t('modals.guests.noGuests')}
          </Text>
          :
          <Table>
            <thead>
              <tr>
                <th>{t('modals.guests.table.username')}</th>
              </tr>
            </thead>
            <tbody>
              {
                roomContext.party.guests.map((g) => (
                  <tr key={g.id}>
                    <td>@{g.username}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        }
      </Modal>

      {/* Party info modal */}
      <Modal
        opened={infoModalOpened}
        setOpened={setInfoModalOpened}
        title={t('modals.info.title')}
        withCloseButton
      >
        {!roomContext.party.id ? null :
          <Table>
            <tbody>
              <tr key='name'>
                <td><Text weight={800}>{t('modals.info.table.name')}</Text></td>
                <td><Text align='right'>{roomContext.party.name}</Text></td>
              </tr>
              <tr key='description'>
                <td><Text weight={800}>{t('modals.info.table.description')}</Text></td>
                <td><Text align='right'>{roomContext.party.description}</Text></td>
              </tr>
              <tr key='host'>
                <td><Text weight={800}>{t('modals.info.table.host')}</Text></td>
                <td><Text align='right'>@{roomContext.party.host.username}</Text></td>
              </tr>
              {!roomContext.party.isPasswordPublic ? null :
                <tr key='password'>
                  <td><Text weight={800}>{t('modals.info.table.password')}</Text></td>
                  <td><Text align='right'>{roomContext.party.password}</Text></td>
                </tr>
              }
              <tr key='votingPoint'>
                <td><Text weight={800}>{t('modals.info.table.votingPoint')}</Text></td>
                <td><Text align='right'>{roomContext.party.votingPoint}</Text></td>
              </tr>
              <tr key='isExplicitAllowed'>
                <td><Text weight={800}>{t('modals.info.table.isExplicitAllowed')}</Text></td>
                <td><Text align='right'>
                  {roomContext.party.isExplicitAllowed ? t('true', { ns: 'common' }) : t('false', { ns: 'common' })}
                </Text></td>
              </tr>
              <tr key='isPasswordPublic'>
                <td><Text weight={800}>{t('modals.info.table.isPasswordPublic')}</Text></td>
                <td><Text align='right'>
                  {roomContext.party.isPasswordPublic ? t('true', { ns: 'common' }) : t('false', { ns: 'common' })}
                </Text></td>
              </tr>
            </tbody>
          </Table>
        }
      </Modal>

      {/* Share modal */}
      <ShareModal
        opened={shareModalOpened}
        setOpened={setShareModalOpened}
        party={roomContext.party}
      />

      <PageContainer
        navbar={
          <>
            {/* PWA Install prompt */}
            <PWAInstallPrompt isFloating />

            {/* Normal navbar */}
            <MixNavbar
              selectedPage={MixPage.Home}
            />
          </>
        }
      >

        <LoadingOverlay
          visible={loading}
          overlayBlur={2}
        />

        <Header>
          <Title>
            {roomContext.party.name}
          </Title>
        </Header>

        {/* Description */}
        <Spoiler
          maxHeight={50}
          showLabel={t('spoiler.showLabel')}
          hideLabel={t('spoiler.hideLabel')}
        >
          <Text>
            {roomContext.party.description}
          </Text>
        </Spoiler>

        {/* Header buttons */}
        <Group
          className={classes.buttonHeader}
        >
          <LabelButton
            icon={<IconUser />}
            label={roomContext.party.guests.length}
            onClick={() => setGuestModalOpened(true)}
          />

          {!roomContext.party.isPasswordPublic && !isHost ? null :
            <Button
              radius={ComponentSize.XL}
              color='black'
              backgroundColor='black'
              onClick={() => setShareModalOpened(true)}
            >
              {t('buttons.partyCode', { code: roomContext.party.password })}
            </Button>
          }

          <Space style={{ flex: 1 }} />

          <ButtonIcon
            color='black'
            backgroundColor='black'
            onClick={() => setInfoModalOpened(true)}
          ><IconInfoCircle /></ButtonIcon>

        </Group>

        {/* Queue Items */}
        {roomContext.party.queue.length === 0 ?
          <Center>
            <Stack>
              <Text
                align='center'
              >
                {t('alerts.noTracks')}
              </Text>
              <Center>
                <InvertableImage
                  src={EmptyStateImage}
                  maw={250}
                />
              </Center>
            </Stack>
          </Center>
          :
          <>
            <Stack>
              {roomContext.party.queue.map((q) =>
                <QueueItem
                  track={q}
                  key={q.id}
                />
              )}
            </Stack>

            <Space h='md' />

            <Text align='center' transform="uppercase" size="sm">
              {t('bottomSpotify.preLink')}
              <Text
                component='a'
                href={`spotify:user:${roomContext.party.host.id}:playlist:${playlistID}`}
                underline
                color='salmon'
              >
                {t('bottomSpotify.link')}
              </Text>
            </Text>

            <Center>
              <Image
                src={require('../../assets/images/spotify-logo-full.png')}
                width={100}
                onClick={() => window.open(`spotify:user:${roomContext.party.host.id}:playlist:${playlistID}`, '_blank')}
                className={classes.spotifyLogo}
              />
            </Center>
          </>
        }
      </PageContainer>
    </>
  )
}