import { motion, useReducedMotion } from "framer-motion";
import { BaseButtonProps, ButtonVariant } from "./_types";
import { useState } from "react";
import { MantineTheme, Sx, UnstyledButton } from "@mantine/core";
import { ComponentSize } from "../_types";

const BUTTON_SIZES = {
  xs: {
    padding: 14,
    height: 30,
    radius: 2,
  },
  sm: {
    padding: 18,
    height: 36,
    radius: 4,
  },
  md: {
    padding: 22,
    height: 42,
    radius: 8,
  },
  lg: {
    padding: 26,
    height: 50,
    radius: 16,
  },
  xl: {
    padding: 32,
    height: 60,
    radius: 32,
  }
}

export default function ButtonBase(props: BaseButtonProps) {

  // Set default values
  const size = props.size || ComponentSize.MD,
    variant = props.variant || ButtonVariant.Light,
    backgroundColor = props.backgroundColor || props.color;


  // Hooks
  const isReducedMotion = useReducedMotion();

  // States
  const [hover, setHover] = useState(false);

  // Styles
  const buttonStyle: Sx = (theme: MantineTheme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    boxSizing: 'border-box',
    flex: 1,
    width: '100%',
    height: props.height ? props.height : size ? BUTTON_SIZES[size].height : BUTTON_SIZES.sm.height,

    opacity: props.disabled ? 0.5 : 1,
    cursor: props.disabled ? 'not-allowed' : null,

    transitionDuration: theme.transitionTimingFunction,
    borderRadius: props.radius ? BUTTON_SIZES[props.radius].radius : BUTTON_SIZES.md.radius,

    padding: props.square ? 0 : `0px ${size ? BUTTON_SIZES[size].padding : BUTTON_SIZES.sm.padding}px`,
    aspectRatio: props.square ? '1 / 1' : null,
    boxShadow: !props.withShadow ? null : `0px 10px 30px 0px rgba(0,0,0,0.2)`,

    backgroundColor: variant === ButtonVariant.Filled ?
      backgroundColor ? theme.colors[backgroundColor][4] : theme.colors[theme.primaryColor][4]
      : variant === ButtonVariant.Light ?
        hover ?
          `${backgroundColor ? theme.colors[backgroundColor][4] : theme.colors[theme.primaryColor][4]}30`
          : `${backgroundColor ? theme.colors[backgroundColor][4] : theme.colors[theme.primaryColor][4]}20`
        : hover ?
          `${backgroundColor ? theme.colors[backgroundColor][4] : theme.colors[theme.primaryColor][4]}30`
          : `00000000`,
    color: variant === ButtonVariant.Filled ?
      props.color ? theme.colors[props.color][4] : theme.white
      : props.color ? theme.colors[props.color][4] : theme.colors[theme.primaryColor][4]
  });

  return (
    <motion.div
      whileHover={{ scale: isReducedMotion || !props.withMotion ? 1 : 1.1 }}
      whileTap={{ scale: isReducedMotion || !props.withMotion ? 1 : 1.05 }}
      style={{ width: props.width, height: props.height }}
      transition={{ ease: "backOut" }}
    >
      <UnstyledButton
        onClick={props.onClick && !props.disabled ? props.onClick : null}
        component={props.component}
        to={props.to}
        href={props.href}

        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}

        sx={buttonStyle}
        style={props.style}
      >
        {props.children}
      </UnstyledButton>
    </motion.div>

  )
}