// Images
import AppIcon from '../../assets/images/appicon.png';

import { Container, Drawer, Group, Image, MantineTheme, Paper, Stack, Sx } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import ButtonIcon from "../_phosphorus/buttons/ButtonIcon";
import { useEffect, useState } from 'react';
import { useDisclosure, useOs, useReducedMotion } from '@mantine/hooks';
import { isMobileSafari } from 'react-device-detect';
import { motion, MotionStyle } from "framer-motion";
import Button from '../_phosphorus/buttons/Button';
import { ButtonVariant } from '../_phosphorus/buttons/_types';
import { ComponentSize } from '../_phosphorus/_types';
import Text from '../_phosphorus/text/FormattedText';
import { TextAlign } from '../_phosphorus/text/_types';

type Props = {
  isFloating?: boolean;
}

function shouldPromptShow() {
  // @ts-ignore
  if (!navigator.standalone
    && !window.matchMedia('(display-mode: standalone)').matches
    && sessionStorage.getItem('installPromptClosed') !== 'true'
  ) return true;
}

export default function PWAInstallPrompt(props: Props) {

  // Hooks
  const { t } = useTranslation('pwa');
  const os = useOs();
  const isReducedMotion = useReducedMotion();

  // States
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [iOSState, setIOSState] = useState<'safari' | 'other'>('other');

  // Disclosures
  const [opened, { open, close }] = useDisclosure(false);

  // Store the install prompt for later use
  // Normal platforms - an event handler that replaces the normal installation prompt
  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();

      if (!shouldPromptShow()) return;
      setShowPrompt(true);
      setPromptInstall(e);
    }

    window.addEventListener("beforeinstallprompt", handler)
    return () => window.removeEventListener("transitionend", handler);

  }, []);

  // iOS - show the prompt anyways, we have a custom flow for iOS
  useEffect(() => {
    if (os === 'ios' && shouldPromptShow()) setShowPrompt(true);
  }, []);

  // Install the PWA
  function install(event: any) {
    // Check the platform
    if (os === 'ios') {
      // Check the browser
      setIOSState(isMobileSafari ? 'safari' : 'other');
      open();
    } else {
      event.preventDefault();
      if (!promptInstall) return;
      promptInstall.prompt();
    }

  }

  // Close the prompt
  function closePrompt(event: any) {
    event.stopPropagation();
    setShowPrompt(false);

    // Stop this prompt from appearing again
    sessionStorage.setItem('installPromptClosed', 'true');
  }

  // Styles
  const paperStyle: Sx = (theme: MantineTheme) => ({
    backgroundColor: theme.black,
    color: theme.white,
  });
  const stackStyle: Sx = () => ({
    flexGrow: 1,
    gap: 5,
  });
  const floatingDivStyle: MotionStyle = {
    position: 'fixed',
    bottom: 70,
    padding: 20,
    width: '100%',
  }

  return (
    <>
      {/* iOS Drawer */}
      <Drawer
        opened={opened}
        onClose={close}
        position='bottom'
        withCloseButton={false}
        closeOnClickOutside
        padding='md'
        size='fit-content'
      >
        <Container
          size='xs'
          px='sm'
          style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
        >
          <Text
            weight={800}
            align={TextAlign.Center}
            size={30}
          >
            {t(`ios.states.${iOSState}.title`)}
          </Text>
          <Text
            align={TextAlign.Center}
          >
            {t(`ios.states.${iOSState}.text`)}
          </Text>

          <Button
            variant={ButtonVariant.Filled}
            style={{ width: '100%' }}
            onClick={close}
          >
            {t(`ios.button`)}
          </Button>
        </Container>
      </Drawer>

      {/* Initial prompt */}
      {!showPrompt ? null :
        <motion.div
          whileHover={{ scale: isReducedMotion ? 1 : 1.02 }}
          whileTap={{ scale: isReducedMotion ? 1 : 1.01 }}
          style={props.isFloating ? floatingDivStyle : null}
        >
          <Paper
            shadow='md'
            radius='lg'
            p='sm'
            sx={paperStyle}
            onClick={install}
          >
            <Group style={{ flexWrap: 'nowrap' }}>
              {/* Image */}
              <Image
                src={AppIcon}
                width={50}
                radius='sm'
              />

              {/* Info */}
              <Stack
                sx={stackStyle}
              >
                <Text
                  weight={800}
                >
                  {isMobileSafari ? t('ios.prompt.title') : t('other.prompt.title')}
                </Text>

                <Text
                  size={ComponentSize.SM}
                >
                  {isMobileSafari ? t('ios.prompt.subtitle') : t('other.prompt.subtitle')}
                </Text>
              </Stack>

              {/* Dismiss button */}
              <ButtonIcon
                color='white'
                backgroundColor='white'
                onClick={closePrompt}
              ><IconX /></ButtonIcon>
            </Group>
          </Paper>
        </motion.div>
      }
    </>
  )
}