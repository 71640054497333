import { CopyButton, Image, Stack, Text, Title } from "@mantine/core";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Mix } from "../../../func/models/mix";
import GlassModal from "./GlassModal";
import Button from "../../_phosphorus/buttons/Button";
import { ButtonVariant } from "../../_phosphorus/buttons/_types";

type Props = {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  party: Mix;
}

export default function ShareModal(props: Props) {
  const party = props.party;
  const partyLink = `${window.location.origin}/?code=${party.password}`;

  const { t } = useTranslation();

  return (
    <GlassModal
      opened={props.opened}
      setOpened={props.setOpened}
    >
      <Stack>
        <Image
          radius='lg'
          src={`https://chart.googleapis.com/chart?cht=qr&chl=${partyLink}&chs=248`}
          alt="Party QR code"
          style={{
            aspectRatio: '1/1',
          }}
        />

        <Title
          order={2}
          align='center'
          color={'permaWhite'}  
        >
          {party.name}
        </Title>

        <Text
          align='center'
          weight={800}
          color='permaWhite'
        >
          {partyLink}
        </Text>

        <CopyButton value={partyLink}>
          {({ copied, copy }) => (
            <Button
              variant={ButtonVariant.Filled}
              onClick={copy}
            >
              {copied ?
                t('linkCopied')
                :
                t('copyLink')
              }
            </Button>
          )}
        </CopyButton>
      </Stack>
    </GlassModal>
  )
}