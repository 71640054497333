import { Loader } from "@mantine/core";
import { ButtonIconProps } from "./_types";
import ButtonBase from "./ButtonBase";
import { ComponentSize } from "../_types";


export default function ButtonIcon(props: ButtonIconProps) { 

  return ( 
    <ButtonBase
      { ...props }
      square={props.square === false ? props.square : true}
      radius={props.radius || ComponentSize.XL}
    >
      { !props.loading ? props.children :
        <Loader
          color={ props.color }
          size='sm'
        />
      }
    </ButtonBase>
  )
}