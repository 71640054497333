import { Group, MantineTheme, Sx } from "@mantine/core";
import { IconDisc, IconLogin, IconSettings2 } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import NavButton from "../../inputs/buttons/NavButton";
import { Link } from "react-router-dom";

export enum SessionPage {
  Join,
  Mixes,
  Settings
}

type Props = {
  selectedPage: SessionPage
}



export default function SessionNavbar(props: Props) {

  /** Hooks */
  const { t } = useTranslation('navbar');



  /** Styles */
  const navStyle: Sx = (theme: MantineTheme) => ({
    justifyContent: 'space-around',
    margin: theme.spacing.xs,
  });



  const PAGES = [
    {
      label: t('buttons.join'),
      icon: <IconLogin />,
      disabled: false,
      to: '/session/join',
    },
    {
      label: t('buttons.mixes'),
      icon: <IconDisc />,
      disabled: false,
      to: '/session/mixes',
    },
    {
      label: t('buttons.settings'),
      icon: <IconSettings2 />,
      disabled: false,
      to: '/session/settings',
    },
  ];

  return (
    <>
      {/* Navbar */}
      <Group
        sx={navStyle}
      >
        {PAGES.map((p, i) =>
          <NavButton
            icon={p.icon}
            label={p.label}
            highlighted={props.selectedPage === i}
            disabled={p.disabled}
            key={i}
            component={Link}
            to={p.to}
          />
        )}
      </Group>
    </>
  )
}