import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';
import { WS_DOMAIN } from '../contexts/consts';
import { RoomContext } from '../contexts/roomContext';
import { ClientSession } from '../models/sessions/clientSession';
import { Message, parseMessage, MessageEvent } from '../models/message';
import { handleMessage } from './messageHandler';

export var globalSJM: SendJsonMessage = null;

export default function WebsocketMain() {

  /** Contexts */
  const roomContext = useContext(RoomContext);

  /** Hooks */
  const navigate = useNavigate();
  const { t } = useTranslation();

  /** Upon first mounting, double-check this clientSession
   * is valid, navigating to the home screen if not
   */
  async function checkSession() {
    let clientSession = new ClientSession();
    clientSession.deserialise();

    if (!clientSession.id || !clientSession.roomID) {
      navigate('/');
      return;
    }
  }
  useEffect(() => { checkSession() }, []);

  /** Create a websocket connection using our clientSession */
  let clientSession = new ClientSession();
  clientSession.deserialise();
  const {
    sendJsonMessage,
  } = useWebSocket(`${WS_DOMAIN}do/room/name/${clientSession.roomID}/websocket?${new URLSearchParams({
    e: 'a',     /** This effectively does nothing, but it works. Idk man */
    userID: clientSession.id,
    username: clientSession.username,
  }).toString()
    }`, {
    onOpen: () => {
      console.log(Date.now(), 'Websocket connection opened');

      /** Initialise the globalSJM */
      globalSJM = sendJsonMessage;

      // Testing
      (new Message(MessageEvent.InitSession, '')).send();
    },
    onClose: (event) => {
      console.log(Date.now(), 'Websocket connection closed', event);
    },
    onMessage: async (messageEvent) => {
      // console.log(Date.now(), 'Message received', messageEvent);

      let message = parseMessage(messageEvent);
      await handleMessage(message, roomContext, navigate, t);
    },
    /** Attempt to reconnect on close events */
    shouldReconnect: () => true,
  });

  return (<></>);
}