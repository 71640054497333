import { Box, MantineTheme, Sx, Text, UnstyledButton } from "@mantine/core";
import { ReactNode, useState } from "react";
import { WHITE_PALETTE } from "../../_colors";

type Props = { 
  icon: ReactNode;
  label: ReactNode;
  highlighted: boolean;
  onClick?: Function;
  component?: any;
  to?: string;
  disabled?: boolean;
}

export default function NavButton(props: Props) { 

  /** States */
  const [ hover, setHover ] = useState(false);

  /** Styles */
  const buttonStyle: Sx = (theme: MantineTheme) => ({
    width: 60,
    borderRadius: 50,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,

    opacity: props.disabled ? 0.5 : 1,
    cursor: props.disabled ? 'not-allowed' : null,
  });
  const iconStyle: Sx = (theme: MantineTheme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '100%',
    height: 30,
    padding: 15,
    borderRadius: 20,
    transitionDuration: theme.transitionTimingFunction,
    backgroundColor: hover ? `${ WHITE_PALETTE[0] }30` : 
      props.highlighted ? `${ WHITE_PALETTE[0] }20` : `${ WHITE_PALETTE[0] }00`,
    color: WHITE_PALETTE[0],
  });
  const textStyle: Sx = (theme: MantineTheme) => ({
    margin: 0,
    transitionDuration: theme.transitionTimingFunction,
    color: WHITE_PALETTE[0],
    opacity: hover || props.highlighted ? 1 : 0.5,
  });

  return ( 
    <UnstyledButton
      onClick={ props.onClick ? () => props.onClick() : null }
      component={ props.component }
      to={ props.to }

      onMouseEnter={ () => setHover(true) }
      onMouseLeave={ () => setHover(false) }
      
      sx={ buttonStyle }
    >
      <Box 
        sx={ iconStyle }
      >
        { props.icon }
      </Box>

      <Text
        size={ 10 }
        weight={ 800 }
        sx={ textStyle }
        align='center'
      >
        { props.label }
      </Text>
    </UnstyledButton>
  )
}