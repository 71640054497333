import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SpotifySession } from "../../func/models/sessions/spotifySession";
import PageContainer from "../../components/layout/PageContainer";
import SessionNavbar, { SessionPage } from "../../components/layout/nav/SessionNavbar";
import Header from "../../components/layout/Header";
import Title from "../../components/text/Title";
import MenuContainer from "../../components/display/MenuContainer";
import { Group, Image, Stack } from "@mantine/core";
import Text from "../../components/_phosphorus/text/FormattedText";
import { ComponentSize } from "../../components/_phosphorus/_types";
import Button from "../../components/_phosphorus/buttons/Button";

export default function SessionSettingsPage() {

  // Hooks
  const { t } = useTranslation('sessionSettings');
  const navigate = useNavigate();

  // States
  const [spotifySession, setSpotifySession] = useState<SpotifySession>(null);



  /** Check the client has a valid Spotify session. If not, 
   * redirect them away from this screen.
   */
  async function detectSession() {
    let spotifySession = await new SpotifySession().deserialise();
    if (!spotifySession) navigate("/");
    else setSpotifySession(spotifySession);
  }

  /** Sign out of the current Spotify session and return home */
  function endSpotifySession() {
    spotifySession.remove();
    navigate('/');
  }

  useEffect(() => { detectSession() }, []);



  return (
    <>
      <PageContainer
        navbar={<SessionNavbar selectedPage={SessionPage.Settings} />}
      >
        <Header>
          <Title>{t("title")}</Title>
        </Header>

        {/* Spotify info/sign out container */}
        {!spotifySession ? null :
          <MenuContainer>
            <Group spacing='sm' style={{ height: '100%' }}>
              <Image
                height={80}
                width={80}
                radius="xl"
                withPlaceholder
                alt="Spotify user avatar"
                src={spotifySession.profileImageURL}
              />

              <Stack
                style={{ flex: 1 }}
                justify='space-between'
              >
                <Text
                  weight={800}
                  size={ComponentSize.LG}
                >
                  {spotifySession.displayName || spotifySession.id}
                </Text>

                <Button
                  onClick={endSpotifySession}
                >
                  {t('menu.spotify.signOut', { ns: "navbar" })}
                </Button>
              </Stack>
            </Group>
          </MenuContainer>
        }
      </PageContainer>
    </>
  )
}