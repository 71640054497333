import PromoImage from "../../../assets/images/image-1-right.png";
import SpotifySVG from "../../../assets/images/spotify-logo.svg";

import { Group, Image, MantineTheme, Stack, Sx } from "@mantine/core";
import { IconArrowBackUp, IconHome, IconMenu2, IconPlus, IconSettings2, IconShare, IconStar, IconStarFilled, IconX } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import NavButton from "../../inputs/buttons/NavButton";
import { useContext, useEffect, useState } from "react";
import QuickActionButton from "../../inputs/buttons/QuickActionButton";
import Column from "../../_phosphorus/layout/ColumnContainer";
import { FlexDirection } from "../../_phosphorus/layout/_types";
import MenuContainer from "../../display/MenuContainer";
import ButtonWithIcon from "../../_phosphorus/buttons/ButtonWithIcon";
import { ButtonVariant } from "../../_phosphorus/buttons/_types";
import { useViewportSize } from "@mantine/hooks";
import { MOBILE_BREAKPOINT_PX } from "../../_phosphorus/layout/_consts";
import { ClientSession } from "../../../func/models/sessions/clientSession";
import { RoomContext } from "../../../func/contexts/roomContext";
import ShareModal from "../modals/ShareModal";
import { SpotifySession } from "../../../func/models/sessions/spotifySession";
import Text from "../../_phosphorus/text/FormattedText";
import { ComponentSize } from "../../_phosphorus/_types";
import Button from "../../_phosphorus/buttons/Button";
import { spotifySignIn } from "../../../func/spotify/spotifyAuth";



export enum MixPage {
  Add,
  Home,
  Menu
}

type Props = {
  selectedPage: MixPage
}



export default function MixNavbar(props: Props) {

  /** Contexts */
  const roomContext = useContext(RoomContext);

  /** Hooks */
  const { t } = useTranslation('navbar');
  const { width } = useViewportSize();
  const isMobile = width <= MOBILE_BREAKPOINT_PX;
  const navigate = useNavigate();

  /** States */
  const [menuOpen, setMenuOpen] = useState(false);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const [spotifySession, setSpotifySession] = useState<SpotifySession>(null);

  /** Check if this session is a Host */
  async function hostCheck() {
    let clientSession = new ClientSession().deserialise();
    setIsHost(clientSession.isHost);
  }

  async function getSpotifySession() {
    setSpotifySession(await new SpotifySession().deserialise());
  }

  function endSpotifySession() {
    spotifySession.remove();
    navigate('/');
  }

  useEffect(() => {
    hostCheck();
    getSpotifySession();
  }, []);

  /** Styles */
  const navStyle: Sx = (theme: MantineTheme) => ({
    justifyContent: 'space-around',
    margin: theme.spacing.xs,
  });

  const menuStyle: Sx = (theme: MantineTheme) => ({
    paddingTop: menuOpen ? theme.spacing.sm : 0,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    gap: theme.spacing.sm,
    boxSizing: 'border-box',
    height: !menuOpen ? 0 : isMobile ? 320 : 200,
    transitionDuration: '0.5s',
    overflow: 'hidden',
  });
  const menuButtonGroupStyle: Sx = (theme: MantineTheme) => ({
    gap: theme.spacing.sm,
    flexWrap: 'nowrap',
  });

  const PAGES = [
    {
      label: t('buttons.add'),
      icon: <IconPlus />,
      disabled: false,
      to: '/mix/add',
    },
    {
      label: t('buttons.home'),
      icon: <IconHome />,
      disabled: false,
      to: '/mix/home',
    },
  ];

  function closeMenu() {
    setMenuOpen(false);
  }

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  function shareMix() {
    if (isHost || roomContext.party.isPasswordPublic) setShareModalOpened(true);
  }

  async function toggleFavouriteMix() {
    if (spotifySession.favouriteRooms.includes(roomContext.party.id))
      await spotifySession.removeFavouriteRoom(roomContext.party.id);
    else
      await spotifySession.addFavouriteRooms([roomContext.party.id]);

    getSpotifySession();
  }

  return (
    <>
      <Stack spacing={0}>
        {/* Menu */}
        <Stack sx={menuStyle}>
          {/* Quick action buttons */}
          <Group sx={menuButtonGroupStyle}>
            <QuickActionButton
              onClick={shareMix}
              disabled={!isHost && !roomContext.party.isPasswordPublic}
            >
              <IconShare />
            </QuickActionButton>

            <QuickActionButton
              onClick={toggleFavouriteMix}
              disabled={isHost}
            >
              {spotifySession && roomContext && spotifySession.favouriteRooms.includes(roomContext.party.id) ? <IconStarFilled /> : <IconStar />}
            </QuickActionButton>

            <QuickActionButton disabled>
            </QuickActionButton>

            <QuickActionButton disabled>
            </QuickActionButton>
          </Group>

          <Column.Container
            direction={{ desktop: FlexDirection.Row, mobile: FlexDirection.Column }}
          >
            {/* Spotify profile/sign in CTA */}
            <Column width={{ desktop: '50%', mobile: '100%' }}>
              {spotifySession && spotifySession.id ?
                // Signed in state
                <MenuContainer>
                  <Group spacing='sm' style={{ height: '100%' }}>
                    <Image
                      height={isMobile ? 80 : 100}
                      width={isMobile ? 80 : 100}
                      radius="xl"
                      withPlaceholder
                      alt="User profile"
                      src={spotifySession.profileImageURL}
                    />

                    <Stack style={{ flex: 1, height: '100%' }} justify='space-between' spacing={0}>
                      <Text
                        weight={800}
                        size={ComponentSize.LG}
                      >
                        {spotifySession.displayName || spotifySession.id}
                      </Text>

                      <Button
                        onClick={endSpotifySession}
                      >
                        {t('menu.spotify.signOut')}
                      </Button>
                    </Stack>
                  </Group>
                </MenuContainer>
                :
                // Signed out state
                <MenuContainer
                  backgroundImage={PromoImage}
                  backgroundStyle={{
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "left",
                    backgroundSize: "25%",
                  }}
                >
                  <Stack
                    spacing="xs"
                    justify="space-between"
                    style={{ height: "100%", overflow: "hidden", marginLeft: "calc(25% + 20px)" }}
                  >
                    <Text color="dimmed">{t("menu.spotify.signIn.prompt")}</Text>
                    <ButtonWithIcon
                      icon={<Image src={SpotifySVG} width={20} />}
                      backgroundColor="spotifyGreen"
                      variant={ButtonVariant.Filled}
                      onClick={() => spotifySignIn({ page: "/session/join", params: { code: !roomContext ? null : roomContext.party.password } })}
                    >
                      {t("menu.spotify.signIn.button")}
                    </ButtonWithIcon>
                  </Stack>
                </MenuContainer>
              }
            </Column>

            {/* Settings & leave mix */}
            <Column width={{ desktop: '50%', mobile: '100%' }}>
              <MenuContainer>
                <Stack justify="space-between">
                  {!isHost ? null :
                    <ButtonWithIcon
                      icon={<IconSettings2 />}
                      color='black'
                      variant={ButtonVariant.Subtle}
                      component={Link}
                      to='/mix/settings'
                      growText
                    >
                      {t('menu.mix.settings')}
                    </ButtonWithIcon>
                  }
                  <ButtonWithIcon
                    icon={<IconArrowBackUp />}
                    variant={ButtonVariant.Filled}
                    component={Link}
                    to='/'
                    growText
                  >
                    {t('menu.mix.exit')}
                  </ButtonWithIcon>
                </Stack>
              </MenuContainer>
            </Column>
          </Column.Container>
        </Stack>

        {/* Navbar */}
        <Group
          sx={navStyle}
        >
          {PAGES.map((p, i) =>
            <NavButton
              icon={p.icon}
              label={p.label}
              highlighted={!menuOpen && props.selectedPage === i}
              disabled={p.disabled}
              key={i}
              component={Link}
              to={p.to}
              onClick={closeMenu}
            />
          )}

          {/* Menu button */}
          <NavButton
            icon={menuOpen ? <IconX /> : <IconMenu2 />}
            label={t('buttons.menu')}
            highlighted={menuOpen}
            key={'menu'}
            onClick={toggleMenu}
          />
        </Group>
      </Stack >

      {/* Share modal */}
      < ShareModal
        party={roomContext.party}
        opened={shareModalOpened}
        setOpened={setShareModalOpened}
      />
    </>
  );
}