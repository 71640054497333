import EmptyStateImage from "../../assets/images/no-mixes.png";
import SpotifyBrand from "../../assets/images/spotify-logo-full.png";
import SpotifySVG from "../../assets/images/spotify-logo.svg";

import { Center, createStyles, Group, Image, LoadingOverlay, Space, Stack, Text as MantineText } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonIcon from "../../components/_phosphorus/buttons/ButtonIcon";
import { TextInput } from "../../components/inputs/text/TextInput";
import Header from "../../components/layout/Header";
import MixNavbar, { MixPage } from "../../components/layout/nav/MixNavbar";
import PageContainer from "../../components/layout/PageContainer";
import Title from "../../components/text/Title";
import { RoomContext } from "../../func/contexts/roomContext";
import { checkMix } from "../../func/models/mix";
import { Track, trackSearch } from "../../func/models/spotify/track";
import WebsocketMain from "../../func/websockets/websocketMain";
import { ComponentSize } from "../../components/_phosphorus/_types";
import SegmentedControl from "../../components/_phosphorus/inputs/SegmentedControl";
import InvertableImage from "../../components/display/InvertableImage";
import { SpotifySession } from "../../func/models/sessions/spotifySession";
import { TrackLarge, TrackSmall } from "../../components/display/spotify/Track";
import { Album, AlbumWithTracks } from "../../func/models/spotify/album";
import AlbumItem from "../../components/display/spotify/AlbumItem";
import { Playlist } from "../../func/models/spotify/playlist";
import PLaylistItem from "../../components/display/spotify/PlaylistItem";
import { TextAlign, TextTransform } from "../../components/_phosphorus/text/_types";
import ButtonWithIcon from "../../components/_phosphorus/buttons/ButtonWithIcon";
import { ButtonVariant } from "../../components/_phosphorus/buttons/_types";
import { spotifySignIn } from "../../func/spotify/spotifyAuth";
import Text from "../../components/_phosphorus/text/FormattedText";

const CONTROL_VALUES = ["songs", "albums", "playlists"];

const useClasses = createStyles((theme) => ({
  inputGroup: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    paddingTop: theme.spacing.md,
    paddingBottom: theme.spacing.md,
    backgroundColor: theme.white,
    gap: theme.spacing.xs,
  },
  resultStack: {
    gap: 5,
    flex: 1,
  },
  spotifyLogo: {
    filter: theme.colorScheme === 'light' ? `grayscale(1)` : `invert(100%) grayscale(1)`,
    cursor: 'pointer',
  },
}));

export default function MixAddPage() {

  // Contexts
  const roomContext = useContext(RoomContext);

  // Hooks
  const { t } = useTranslation('mixAdd');
  const { classes } = useClasses();
  const navigate = useNavigate();

  // States
  const [loading, setLoading] = useState(true);
  const [spotifySession, setSpotifySession] = useState<SpotifySession>(null);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<Track[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [controlValue, setControlValue] = useState(CONTROL_VALUES[0]);
  const [libraryList, setLibraryList] = useState<ReactNode[]>([]);



  /** Stop loading the page when the roomInfo updates */
  function stopLoading() {
    if (!checkMix(roomContext, navigate)) return;
    if (roomContext.party.id) setLoading(false);
  }

  /** Detect the spotifySession */
  async function getSpotifySession() {
    setSpotifySession(await new SpotifySession().deserialise());
  }

  /** Search by the query */
  async function searchForSong() {
    if (!query.trim()) {
      setIsSearching(false);
      return;
    }
    setIsSearching(true);
    setResults(await trackSearch(query, roomContext));
  }

  /** Updates the library part of the screen for signed-in users */
  async function updateLibrary() {
    if (!spotifySession) return;

    switch (controlValue) {
      case "songs": {
        let tracks = await spotifySession.getSavedTracks(50);
        setLibraryList(tracks.map((t: Track) =>
          <TrackSmall track={t} key={t.id} />
        ));
        break;
      }
      case "albums": {
        let albums = await spotifySession.getSavedAlbums();
        setLibraryList(albums.map((a: AlbumWithTracks) =>
          <AlbumItem album={a} key={a.id} />
        ))
        break;
      }
      case "playlists": {
        let playlists = await spotifySession.getSavedPlaylists();
        setLibraryList(playlists.map((p: Playlist) =>
          <PLaylistItem playlist={p} key={p.id} />
        ));
        break;
      }
      default: return;
    }
  }

  useEffect(() => { getSpotifySession(); stopLoading(); }, [roomContext]);
  useEffect(() => { updateLibrary(); }, [controlValue, spotifySession]);

  return (
    <>
      <WebsocketMain />

      <PageContainer
        navbar={
          <MixNavbar
            selectedPage={MixPage.Add}
          />
        }
      >
        <LoadingOverlay
          visible={loading}
          overlayBlur={2}
        />

        <Header>
          <Title>
            {t('title')}
          </Title>
        </Header>

        <Group
          className={classes.inputGroup}
        >
          <TextInput
            placeholder={t('inputs.search.placeholder')}
            icon={<IconSearch />}
            value={query}
            setValue={setQuery}
            onEnterPress={searchForSong}
            style={{ flexGrow: 1 }}
            autofocus
          />

          <ButtonIcon
            radius={ComponentSize.MD}
            onClick={searchForSong}
          ><IconSearch /></ButtonIcon>
        </Group>

        {!isSearching ? !spotifySession ?
          // If the user is not signed in, show this section
          <>
            <Stack>
              <Text
                color="dimmed"
                align={TextAlign.Center}
                size={ComponentSize.SM}
              >
                {t("spotify.signIn.prompt")}
              </Text>

              <ButtonWithIcon
                icon={<Image src={SpotifySVG} width={20} />}
                backgroundColor="spotifyGreen"
                variant={ButtonVariant.Filled}
                onClick={() => spotifySignIn({ page: "/session/join", params: { code: !roomContext ? null : roomContext.party.password } })}
              >
                {t("spotify.signIn.button")}
              </ButtonWithIcon>
            </Stack>
          </>
          :
          // If the user is currently searching for something, don't show this section
          <>
            <SegmentedControl
              data={CONTROL_VALUES.map(v => {
                return {
                  label: t(`inputs.libraryControl.${v}`),
                  value: v
                }
              })}
              value={controlValue}
              onChange={setControlValue}
            />

            {libraryList.length === 0 ?
              <Center>
                <Stack>
                  <Text align={TextAlign.Center}>
                    {t("alerts.emptyLibrary")}
                  </Text>
                  <Center>
                    <InvertableImage
                      src={EmptyStateImage}
                      maw={250}
                    />
                  </Center>
                </Stack>
              </Center>
              :
              <Stack spacing={5}>
                {libraryList.map((e) => e)}

                <Space h='md' />

                <Text
                  align={TextAlign.Center}
                  size={ComponentSize.SM}
                  transform={TextTransform.Uppercase}
                >
                  {t('spotify.keepLooking.prompt')}
                  <MantineText
                    component='a'
                    href={`spotify:home`}
                    underline
                    color='salmon'
                  >
                    {t('spotify.keepLooking.link')}
                  </MantineText>
                </Text>

                <Center>
                  <Image
                    src={SpotifyBrand}
                    width={100}
                    onClick={() => window.open(`spotify:home`, '_blank')}
                    className={classes.spotifyLogo}
                  />
                </Center>
              </Stack>
            }
          </>
          :
          results.length === 0 ?
            // If the user has searched for something, but nothing is returned, how this
            <Center>
              <Stack>
                <Text
                  align={TextAlign.Center}
                >
                  {t("alerts.emptySearch")}
                </Text>
                <Center>
                  <InvertableImage
                    src={EmptyStateImage}
                    maw={250}
                  />
                </Center>
              </Stack>
            </Center>
            :
            // If the user has searched and found stuff, show this
            <Stack
              className={classes.resultStack}
            >
              {results.map((t) =>
                <TrackLarge
                  track={t}
                  key={t.id}
                />
              )}

              <Space h='md' />

              <Text 
                align={TextAlign.Center}
                size={ComponentSize.SM}
                transform={TextTransform.Uppercase}
              >
                {t('spotify.keepLooking.prompt')}
                <MantineText
                  component='a'
                  href={`spotify:search:${query}`}
                  underline
                  color='salmon'
                >
                  {t('spotify.keepLooking.link')}
                </MantineText>
              </Text>

              <Center>
                <Image
                  src={SpotifyBrand}
                  width={100}
                  onClick={() => window.open(`spotify:search:${query}`, '_blank')}
                  className={classes.spotifyLogo}
                />
              </Center>
            </Stack>

        }
      </PageContainer>
    </>
  )
}