import { Track } from "./track";

export class Playlist { 
  id: string;
  name: string;
  description: string;
  uri: string;
  tracks: Track[];

  constructor (
    id: string,
    name: string,
    description: string,
    uri: string,
    tracks: Track[]
  ) { 
    this.id = id;
    this.name = name;
    this.description = description;
    this.uri = uri;
    this.tracks = tracks;
  }
}