import { spotifyFetch } from "../../spotify/spotifyFetch";
import { Album } from "./album";
import { Artist } from "./artist";
import { requestClientCredentials } from "../../spotify/spotifyAuth";
import { Thumbnail } from "./thumbnail";
import { IRoomContext } from "../../contexts/roomContext";

export class Track { 
  id: string;
  name: string;
  uri: string;
  album: Album;
  artists: Artist[];
  duration: number;
  isExplicit: boolean;

  constructor ( 
    id: string,
    name: string,
    uri: string,
    album: Album,
    artists: Artist[],
    duration: number,
    isExplicit: boolean,
  ) { 
    this.id = id;
    this.name = name;
    this.uri = uri;
    this.album = album;
    this.artists = artists;
    this.duration = duration;
    this.isExplicit = isExplicit;
  }

  joinArtists() { 
    return this.artists.reduce((acc, cur) => acc + cur.name + ', ', '').slice(0, -2);
  }
}

/** An extension of the standard track class that contains metadata
 * specific to Houseparty
 */
export class TrackMeta extends Track { 
  /** The session ID of the user who added this song */
  userID: string;
  /** IDs of users who have voted against this track */
  votedAgainst: string[];

  constructor ( 
    id: string,
    name: string,
    uri: string,
    album: Album,
    artists: Artist[],
    duration: number,
    isExplicit: boolean,
    userID: string,
    votedAgainst: string[],
  ) { 
    super(id, name, uri, album, artists, duration, isExplicit);
    this.userID = userID;
    this.votedAgainst = votedAgainst;
  }
}


/** Searches for tracks with the provided key
 * Returns a list of Tracks
 */
export async function trackSearch(
  query: string,
  roomContext: IRoomContext,
): Promise<Track[]> { 
  query = query.trim();
  if (!query) return [];

  let credentials = await requestClientCredentials();

  let response = await spotifyFetch(`search/?${
    new URLSearchParams({
      q: query,
      type: 'track',
    }).toString()
  }`, 'GET', credentials.accessToken);
  if (!response.ok) { 
    console.log('Error while searching for track', response.status, response.statusText);
    return [];
  }

  /** Map to tracks */
  const result = await response.json();
  if (result.tracks.items.length === 0) return [];

  let tracks: Track[] = result.tracks.items.map((i: any) => new Track(
    i.id,
    i.name,
    i.uri,
    new Album(
      i.album.id,
      i.album.name,
      i.album.uri,
      i.album.release_date,
      new Thumbnail(
        i.album.images[0].url,
        i.album.images[0].height,
        i.album.images[0].width,
      ),
    ),
    i.artists.map((a: any) => new Artist(
      a.id,
      a.name,
      a.uri,
    )),
    i.duration_ms,
    i.explicit,
  ));

  /** Filter the tracks */
  tracks = tracks.filter((t) => roomContext.party.isExplicitAllowed || !t.isExplicit);
  
  return tracks;
}