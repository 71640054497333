import { BackgroundImage, MantineTheme, Paper, Sx } from "@mantine/core"
import { CSSProperties, ReactNode } from "react";

type Props = {
  children: ReactNode;
  backgroundImage?: string;
  backgroundStyle?: CSSProperties;
}

export default function MenuContainer(props: Props) {

  const containerStyle: Sx = (theme: MantineTheme) => ({
    width: '100%',
    height: "100%",
    boxShadow: `0px 10px 30px 0px rgba(0,0,0,0.2)`,
    overflow: "hidden",
  });
  const imageStyle: Sx = (theme: MantineTheme) => ({ 
    width: '100%',
    height: '100%',
    padding: theme.spacing.sm,
  })

  return (
    <Paper
      radius='lg'
      sx={containerStyle}
    >
      <BackgroundImage
        src={props.backgroundImage}
        sx={imageStyle}
        style={props.backgroundStyle}
      >
        {props.children}
      </BackgroundImage>
    </Paper>
  )
}