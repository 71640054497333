import { createStyles, Image, LoadingOverlay, Stack } from "@mantine/core";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NumberInput } from "../../components/inputs/text/NumberInput";
import { Textarea } from "../../components/inputs/text/Textarea";
import { TextInput } from "../../components/inputs/text/TextInput";
import Header from "../../components/layout/Header";
import InputStack from "../../components/layout/InputStack";
import Paginator from "../../components/layout/nav/Paginator";
import PageContainer from "../../components/layout/PageContainer";
import SmallTitle from "../../components/text/SmallTitle";
import Title from "../../components/text/Title";
import { RoomContext } from "../../func/contexts/roomContext";
import { Mix } from "../../func/models/mix";
import { updateParty } from "../../func/websockets/messageSender";
import WebsocketMain from "../../func/websockets/websocketMain";
import Button from "../../components/_phosphorus/buttons/Button";
import { ButtonVariant } from "../../components/_phosphorus/buttons/_types";
import { ComponentSize } from "../../components/_phosphorus/_types";
import Text from "../../components/_phosphorus/text/FormattedText";
import { TextAlign } from "../../components/_phosphorus/text/_types";

const useClasses = createStyles((theme) => ({
  pageStack: {
    flex: 1,
    overflowY: 'auto',
  },
}));

export default function MixSetupPage() {

  /** States */
  const [page, setPage] = useState(0);

  return (
    <>
      <WebsocketMain />

      <PageContainer
        navbar={
          <Paginator
            totalPages={3}
            currentPage={page}
          />
        }
        fillHeight
      >
        {page === 0 ?
          <Screen1 setPage={setPage} />
          : page === 1 ?
            <Screen2 setPage={setPage} />
            :
            <Screen3 setPage={setPage} />
        }
      </PageContainer>
    </>
  )
}

type PageProps = {
  setPage: Dispatch<SetStateAction<number>>;
}

function Screen1(props: PageProps) {

  /** Hooks */
  const { t } = useTranslation('mixSetup');
  const { classes } = useClasses();

  return (
    <>
      <Stack
        justify='center'
        align='center'
        className={classes.pageStack}
      >
        <Image
          src={require('../../assets/images/setup-image-1.png')}
          width={300}
        />

        <Title
          align='center'
        >
          {t('1.title')}
        </Title>

        <Text
          align={TextAlign.Center}
        >
          {t('1.text')}
        </Text>

      </Stack>

      <Button
        variant={ButtonVariant.Filled}
        onClick={() => props.setPage(1)}
      >
        {t('1.buttons.next')}
      </Button>
    </>
  );
}

function Screen2(props: PageProps) {

  /** Contexts */
  const roomContext = useContext(RoomContext);

  /** Hooks */
  const { t } = useTranslation('mixSetup');
  const { classes } = useClasses();

  /** States */
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [votingPoint, setVotingPoint] = useState(2);

  /** Submit information to the server through the websocket */
  function submitInformation() {
    setLoading(true);

    /** Update the roomContext with a new party */
    roomContext.setParty((prev: Mix) => ({
      ...prev,
      name: name,
      description: description,
      votingPoint: votingPoint,
    }));

    updateParty(
      name,
      description,
      votingPoint,
      null,
      null,
    );

    setLoading(false);
    props.setPage(2);
  }

  return (
    <>
      <LoadingOverlay
        visible={loading}
        overlayBlur={2}
      />

      <Stack
        className={classes.pageStack}
      >
        <Header>
          <Title>
            {t('2.title')}
          </Title>
        </Header>

        <Text>
          {t('2.text')}
        </Text>

        {/* Basics */}
        <SmallTitle>
          {t('basics.title', { ns: 'mixSettings' })}
        </SmallTitle>

        <InputStack>
          <TextInput
            placeholder={t('basics.inputs.name.placeholder', { ns: 'mixSettings' })}
            value={name}
            setValue={setName}
          />
          <Textarea
            placeholder={t('basics.inputs.description.placeholder', { ns: 'mixSettings' })}
            value={description}
            setValue={setDescription}
            maxLength={500}
          />
        </InputStack>


        {/* Voting point */}
        <SmallTitle>
          {t('votingPoint.title', { ns: 'mixSettings' })}
        </SmallTitle>

        <InputStack>
          <NumberInput
            placeholder={t('votingPoint.inputs.votingPoint.placeholder', { ns: 'mixSettings' })}
            min={0}
            value={votingPoint}
            onChange={(val) => setVotingPoint(val)}
          />
          <Text
            size={ComponentSize.SM}
          >
            {t('votingPoint.inputs.votingPoint.label', { ns: 'mixSettings' })}
          </Text>
        </InputStack>
      </Stack>

      <Button
        variant={ButtonVariant.Filled}
        onClick={() => submitInformation()}
        disabled={name.length === 0}
      >
        {t('2.buttons.next')}
      </Button>
    </>
  );
}

function Screen3(props: PageProps) {

  /** Hooks */
  const { t } = useTranslation('mixSetup');
  const { classes } = useClasses();
  const navigate = useNavigate();

  return (
    <>
      <Stack
        justify='center'
        align='center'
        className={classes.pageStack}
      >
        <Image
          src={require('../../assets/images/setup-image-2.png')}
          width={300}
        />

        <Title
          align='center'
        >
          {t('3.title')}
        </Title>

        <Text
          align={TextAlign.Center}
        >
          {t('3.text')}
        </Text>

      </Stack>

      <Stack style={{ gap: 10 }}>
        <Button
          onClick={() => props.setPage(1)}
        >
          {t('3.buttons.share')}
        </Button>

        <Button
          variant={ButtonVariant.Filled}
          onClick={() => navigate('/mix/home')}
        >
          {t('3.buttons.next')}
        </Button>
      </Stack>
    </>
  );
}