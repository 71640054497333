import { Group, Loader, MantineTheme, PinInput, Stack, Sx } from "@mantine/core";
import { ReactNode, useEffect, useState } from "react"
import ButtonIcon from "../../_phosphorus/buttons/ButtonIcon";
import { IconLogin } from "@tabler/icons-react";
import { ButtonVariant } from "../../_phosphorus/buttons/_types";
import { ComponentSize } from "../../_phosphorus/_types";
import { findRoomByPasscode } from "../../../func/models/room";
import { IAlert } from "../../../func/interfaces/IAlert";
import { useTranslation } from "react-i18next";
import Alert from "../../display/Alert";

type Props = {
  onRoomFound?: Function;
  onRoomNotFound?: Function;

  buttonIcon?: ReactNode;
}

/** Read the party code from the URL parameters */
export function readPartyCode() {
  const params = (new URLSearchParams(window.location.search));
  return params.get('code');
}

export default function PasscodeInput(props: Props) {

  // Hooks
  const { t } = useTranslation('join');

  // States
  const [passcode, setPasscode] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<IAlert>(null);



  /** Attempt to find a code within the URL of a party to connect to */
  function findPartyCode() {
    setPasscode(readPartyCode() || "");
  }

  /** Attempt to find a room using the supplied code
   * If successful, it will call the `onRoomFound` method. If not, 
   * it will error and call the `onRoomNotFound` method.
   */
  async function findRoom() {
    if (loading || !passcode) return;
    setLoading(true);

    const room = await findRoomByPasscode(passcode);
    setLoading(false);

    if (!room) {
      // Error
      setAlert({
        title: t('alerts.mixNotFound.title'),
        description: t('alerts.mixNotFound.description'),
      });
      setPasscode("");

      if (props.onRoomNotFound) props.onRoomNotFound();
      return;
    }

    if (props.onRoomFound) props.onRoomFound(passcode);
  }

  useEffect(() => { 
    findPartyCode();
  }, []);




  // Styles
  const stackStyle: Sx = (theme: MantineTheme) => ({
    width: "100%",
  });
  const containerStyle: Sx = (theme: MantineTheme) => ({
    width: "100%",
    gap: theme.spacing.md,
    flexWrap: "nowrap",
  });
  const pinInputStyle: Sx = (theme: MantineTheme) => ({
    flex: 1,
    gap: theme.spacing.xs,
    height: 42,

    "& > .mantine-PinInput-wrapper": {
      height: "100%",

      "& > .mantine-PinInput-input": {
        backgroundColor: `${theme.black}15`,
        border: "none",
        fontFamily: theme.fontFamilyMonospace,
        height: "100%",
        width: "100%",
        textTransform: "uppercase",

        '&:focus': {
          outline: `1px solid ${theme.black}`,
        },
      }
    }
  });



  return (
    <Stack sx={stackStyle}>
      <Group sx={containerStyle}>
        <PinInput
          length={6}
          type="number"
          disabled={loading}
          onComplete={findRoom}
          onChange={(value) => setPasscode(value)}
          value={passcode}
          sx={pinInputStyle}
        />

        <ButtonIcon
          onClick={findRoom}
          variant={ButtonVariant.Filled}
          radius={ComponentSize.MD}
          style={{ width: 65 }}
        >
          {loading ? <Loader size='sm' color='white' /> : props.buttonIcon || <IconLogin />}
        </ButtonIcon>
      </Group>

      {/* Alert */}
      <Alert alert={alert} />
    </Stack>
  )
}