import { Box, Container, MantineTheme, Sx } from "@mantine/core";
import { ReactNode } from "react";
import { BLACK_PALETTE, defaultGradient } from "../_colors";

type Props = {
  children: ReactNode;
  fillHeight?: boolean;
  gap?: number;
  navbar?: ReactNode;
}

export default function PageContainer(props: Props) {

  /** Styles */
  const pageStyle: Sx = (theme: MantineTheme) => ({
    width: `100vw`,
    height: `100vh`,
    maxWidth: `100vw`,
    maxHeight: '100vh',
    boxSizing: 'border-box',

    padding: 0,
    margin: 0,

    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  });
  const backgroundStyle: Sx = (theme: MantineTheme) => ({
    backgroundColor: theme.white,
    height: '100%',
    maxHeight: '100vh',
    width: '100vw',
    boxSizing: 'border-box',
    flex: 1,

    paddingBottom: props.navbar ? 100 : 20,

    overflowY: 'auto',
  });
  const containerStyle: Sx = (theme: MantineTheme) => ({
    height: props.fillHeight ? '100%' : 'auto',
    padding: theme.spacing.md,
    gap: props.gap || theme.spacing.md,
    boxSizing: 'border-box',
    flex: 1,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: props.fillHeight ? 'center' : '',

    position: 'relative',
  });

  const navStyle: Sx = (theme: MantineTheme) => ({
    position: 'fixed',
    bottom: 0,
    backgroundImage: defaultGradient(theme),
    backgroundColor: theme.colors[theme.primaryColor][4],
    boxShadow: `0px 40px 80px -40px ${BLACK_PALETTE[0]}80 inset`,
    zIndex: 99,
  });
  const navTopStyle: Sx = (theme: MantineTheme) => ({
    backgroundColor: theme.white,
    width: '100vw',
    height: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  });

  return (
    <Box
      sx={pageStyle}
    >
      <Box
        sx={backgroundStyle}
      >
        <Container
          size='xs'
          px='sm'
          sx={containerStyle}
        >
          {props.children}
        </Container>
      </Box>

      {!props.navbar ? null :
        <Box
          sx={navStyle}
        >
          <Box
            sx={navTopStyle}
          />
          {props.navbar}
        </Box>
      }
    </Box>
  )
}