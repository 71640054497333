import { Dispatch, ReactNode, SetStateAction } from "react"
import { Group, Modal as MantineModal, Stack } from "@mantine/core";
import SmallTitle from "../../text/SmallTitle";
import ButtonIcon from "../../_phosphorus/buttons/ButtonIcon";
import { IconX } from "@tabler/icons-react";
import { ComponentSize } from "../../_phosphorus/_types";
import { BLACK_PALETTE } from "../../_colors";

type Props = {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
  title?: ReactNode;

  overlayBlur?: number;
  overlayOpacity?: number;

  size?: ComponentSize;
  radius?: ComponentSize;
  padding?: ComponentSize;

  withCloseButton?: boolean;
}

export default function Modal(props: Props) {
  return (
    <MantineModal
      opened={props.opened}
      onClose={() => props.setOpened(false)}

      overlayProps={{
        color: BLACK_PALETTE[0],
        opacity: props.overlayOpacity || 0.6,
        blur: props.overlayBlur || 3
      }}

      size={props.size || ComponentSize.MD}
      radius={props.radius || ComponentSize.LG}
      padding={props.padding || ComponentSize.MD}

      centered={true}
      lockScroll={true}
      closeOnClickOutside={true}
      closeOnEscape={true}
      withCloseButton={false}
    >
      <Stack>
        <Group style={{ justifyContent: 'space-between' }}>
          {!props.title ? null :
            <SmallTitle 
              color='salmon'
              order={3}
            >
              {props.title}
            </SmallTitle>
          }

          {!props.withCloseButton ? null :
            <ButtonIcon
              onClick={() => props.setOpened(false)}
              size={ComponentSize.SM}
              color='black'
            ><IconX/></ButtonIcon>
          }
        </Group>

        {props.children}
      </Stack>
    </MantineModal>
  )
}