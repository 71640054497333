import { Image, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { TFunction } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { IRoomContext } from "../contexts/roomContext";
import { ClientSession } from "../models/sessions/clientSession";
import { Message, MessageEvent } from "../models/message";
import { findUserInMix, Mix } from "../models/mix";
import { Album } from "../models/spotify/album";
import { Artist } from "../models/spotify/artist";
import { Thumbnail } from "../models/spotify/thumbnail";
import { TrackMeta } from "../models/spotify/track";
import { User } from "../models/user";

export async function handleMessage(
  message: Message,
  roomContext: IRoomContext,
  navigate: NavigateFunction,
  t: TFunction<"translation", undefined, "translation">,
) {
  console.log(Date.now(), 'Message received', message);

  const clientSession = new ClientSession();
  clientSession.deserialise();

  switch (message.event) {
    case MessageEvent.Error: {
      console.error('WEBSOCKET ERROR:', message.content);
      return;
    }
    case MessageEvent.AwaitInit: {
      return await initRoom(clientSession);
    }
    case MessageEvent.InitRoom: {
      return await roomInitialised();
    }
    case MessageEvent.RoomUpdate: {
      return await roomUpdated(message, roomContext);
    }
    case MessageEvent.RoomDelete: {
      return navigate('/');
    }
    default: {
      return await globalNotification(message, t, roomContext);
    }
  }
}



/** Initialise the room, providing it basic information to get started */
async function initRoom(clientSession: ClientSession) {
  /** Check if this session is authorised to do this - they need to 
   * be the host of this room 
   */
  if (!clientSession.isHost) {
    console.error('This user is unauthorised to initialise the room');
    return;
  }

  /** Compile information about the room stored in the clientSession
   * This is done here because of limitations in my structuring of the 
   * workers. Very annoying stuff
   */
  (new Message(
    MessageEvent.InitRoom,
    {
      hostID: clientSession.id,
      roomID: clientSession.roomID,
    }
  )).send();
}

/** The room has been initialised - initialise the session */
async function roomInitialised() {
  (new Message(
    MessageEvent.InitSession,
    {},
  )).send();
}

/** The session has been initialise - parse the room information */
async function roomUpdated(message: Message, roomContext: IRoomContext) {
  let partyInfo: Mix = message.content.party;

  /** Use the retrieved information to create a party object */
  let party = new Mix(
    partyInfo.id,
    new User(
      partyInfo.host.id,
      partyInfo.host.username,
      partyInfo.host.isHost,
    ),
    partyInfo.guests.map((g) => new User(
      g.id,
      g.username,
      g.isHost,
    )),
    partyInfo.name,
    partyInfo.description,
    partyInfo.password,
    partyInfo.votingPoint,
    partyInfo.isExplicitAllowed,
    partyInfo.isPasswordPublic,
    partyInfo.queue.map((q) => new TrackMeta(
      q.id,
      q.name,
      q.uri,
      new Album(
        q.album.id,
        q.album.name,
        q.album.uri,
        q.album.releaseDate,
        new Thumbnail(
          q.album.thumbnail.url,
          q.album.thumbnail.height,
          q.album.thumbnail.width,
        ),
      ),
      q.artists.map((a) => new Artist(
        a.id,
        a.name,
        a.uri,
      )),
      q.duration,
      q.isExplicit,
      q.userID,
      q.votedAgainst,
    )),
  );

  roomContext.setParty(party);
}

async function globalNotification(
  message: Message,
  t: TFunction<"translation", undefined, "translation">,
  roomContext: IRoomContext,
) {
  switch (message.event) {
    case MessageEvent.TrackAdd: {
      let host = findUserInMix(roomContext.party, message.content.userID);

      for (const tr of message.content.tracks) {
        let track = new TrackMeta(
          tr.id,
          tr.name,
          tr.uri,
          new Album(
            tr.album.id,
            tr.album.name,
            tr.album.uri,
            tr.album.releaseDate,
            tr.thumbnail,
          ),
          tr.artists.map((a) => new Artist(
            a.id,
            a.name,
            a.uri,
          )),
          tr.duration,
          tr.isExplicit,
          tr.userID,
          tr.votedAgainst,
        )

        showNotification({
          title: <Text weight={800} lineClamp={2} color='black'>
            {`${track.name} • ${track.joinArtists()} • ${track.album.name}`}
          </Text>,
          message: t('notifications.trackAdd.message', {
            username: host ? host.username : 'unknown'
          }),
          icon: <Image width={20} src={require('../../assets/images/spotify-logo.png')} style={{ filter: 'invert(1)' }} />,
          radius: 'md',
          color: 'blue',
        });
      }
      return;
    }
    case MessageEvent.TrackRemove: {
      for (const tr of message.content.tracks) {
        let track = new TrackMeta(
          tr.id,
          tr.name,
          tr.uri,
          new Album(
            tr.album.id,
            tr.album.name,
            tr.album.uri,
            tr.album.releaseDate,
            tr.thumbnail,
          ),
          tr.artists.map((a) => new Artist(
            a.id,
            a.name,
            a.uri,
          )),
          tr.duration,
          tr.isExplicit,
          tr.userID,
          tr.votedAgainst,
        )

        showNotification({
          title: <Text weight={800} lineClamp={2} color='black'>
            {`${track.name} • ${track.joinArtists()} • ${track.album.name}`}
          </Text>,
          message: t('notifications.trackRemove.message'),
          // icon: <IconTrash size={20} />,
          icon: <Image width={20} src={require('../../assets/images/spotify-logo.png')} style={{ filter: 'invert(1)' }} />,
          radius: 'md',
          color: 'salmon',
        });
      }
      return;
    }
    default: {
      console.log('Unhandled event', message.event);
      return;
    }
  }
}