import { Center, Loader } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../components/display/Alert";
import PageContainer from "../components/layout/PageContainer";
import { IAlert } from "../func/interfaces/IAlert";
import { ClientSession } from "../func/models/sessions/clientSession";
import { findRoomByHostID, Room } from "../func/models/room";
import { spotifyAuthCallback } from "../func/spotify/spotifyAuth";
import { SpotifySession } from "../func/models/sessions/spotifySession";

export default function AuthPage() {

  /** Hooks */
  const { t } = useTranslation('join');
  const search = useLocation().search;
  const navigate = useNavigate();

  /** States */
  const [alert, setAlert] = useState<IAlert>(null);

  useEffect(() => {
    const authenticate = async () => {
      let searchParams = new URLSearchParams(search);
      const response = await spotifyAuthCallback(searchParams);

      // Check for alerts
      if (response === 'invalidCreds') {
        navigate('/');
        return;
      } else if (response === 'error') {
        setAlert({
          title: t('alerts.accountFail.title'),
          description: t('alerts.accountFail.description'),
        });
        return;
      }

      const userID = response.session.userID;
      const credentials = response.session.credentials;

      /** TODO: 
       * Either in v1.1 or 1.2, this whole system needs to be refreshed to use
       * the refreshToken supplied via the SpotifySession, instead of creating
       * a new room and storing the refreshToken internally. The data is there,
       * I'm just not sure if I want to attack reworking how the refreshToken
       * is retrieved for use within the mix right now.
       */

      // Use these credentials to find or create a room
      // let room = await findRoomByHostID(userID);
      // if (!room) {
      //   // Create a new room 
      //   room = new Room('', '', userID, credentials.refreshToken, '');
      //   await room.create();
      // }

      // /** Create a new ClientSession with the information we have,
      //  * then serialise it for later
      //  */
      // new ClientSession(
      //   userID,
      //   response.session.displayName,
      //   room.id,
      //   true,
      // ).serialise();

      // Create a new SpotifySession with this information
      let spotifySession = new SpotifySession(
        userID,
        response.session.displayName,
        response.session.profileImageURL,
      );
      spotifySession.serialise();                           // Serialise locally
      spotifySession.create(credentials.refreshToken);      // Add/update on the server

      // Use our redirect parameters to redirect to a useful page
      let nav = response.redirect.page;
      if (response.redirect.params) nav += "?" + Object.entries(response.redirect.params).map(([key, value]) => `${key}=${value}&`);
      navigate(nav);
    }
    authenticate();
  }, []);

  return (
    <>
      <PageContainer fillHeight>
        {!alert ?
          <Center>
            <Loader />
          </Center>
          :
          <Alert
            alert={alert}
          />
        }
      </PageContainer>
    </>
  )
}