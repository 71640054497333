export class User {
  /** The identifier of this user */
  id: string;
  /** The chosen or assigned name of this user */
  username: string;
  /** Whether this user self-identifies as the host */
  isHost: boolean;

  constructor( 
    id: string,
    username: string,
    isHost: boolean,
  ) { 
    this.id = id;
    this.username = username;
    this.isHost = isHost;
  }
}