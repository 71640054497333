import { createStyles, Group, Switch as MantineSwitch, Text } from "@mantine/core";
import { Dispatch, SetStateAction } from "react";
import { ComponentSize } from "../../_phosphorus/_types";

type Props = {
  label: string,
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
  size?: ComponentSize;
}

export default function Switch(props: Props) {
  const useClasses = createStyles((theme) => ({
    switch: {
      display: 'flex',
      '& .mantine-Switch-track': {
        backgroundColor: `${theme.black}40`,
      },
    },
  }));

  const { classes } = useClasses();

  return (
    <Group
      sx={(theme) => ({
        backgroundColor: `${theme.black}10`,
        padding: theme.spacing.sm,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
      })}
      position="apart"
      onClick={() => props.setChecked(!props.checked)}
    >
      <Text
        sx={(theme) => ({
          color: theme.black,
        })}
      >
        {props.label}
      </Text>
      <MantineSwitch
        size={props.size || ComponentSize.MD}
        checked={props.checked}
        className={classes.switch}
        onClick={(event) => event.stopPropagation()}
      />
    </Group>
  )
}