import { Group, Image, Stack } from "@mantine/core";
import { MantineTheme, Sx } from "@mantine/styles";
import SpotifyLogo from "./SpotifyLogo";
import { MetaRowProps } from "./_types";
import Text from "../../_phosphorus/text/FormattedText";
import { ComponentSize } from "../../_phosphorus/_types";

/** A basic component used to display metadata and basic actions
 * about a Spotify object (Track, Album, Playlist, etc.)
 */
export default function MetaRow(props: MetaRowProps) {

  // Styles
  const groupStyle: Sx = (theme: MantineTheme) => ({
    width: "100%",
    flex: 1,
    flexGrow: 1,
  });
  const textStackStyle: Sx = (theme: MantineTheme) => ({
    gap: 0,
    flex: 1,
    flexGrow: 1,
  });



  return (
    <Group
      align="center"
      position="left"
      sx={groupStyle}
      style={props.style}
    >
      {!props.spotifyLogo ? null :
        <SpotifyLogo
          {...props.spotifyLogo}
        />
      }

      {!props.image ? null :
        <Image
          height={40}
          width={40}
          src={props.image}
        />
      }

      <Stack sx={textStackStyle}>
        {typeof props.title === "string" ?
          <Text weight={800} lineClamp={1}>
            {props.title}
          </Text>
          :
          props.title
        }

        <Text size={ComponentSize.SM} lineClamp={1}>
          {props.subtitle}
        </Text>
      </Stack>

      {props.rightButtons}
    </Group>
  )
}