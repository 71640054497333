import { ComponentSize } from "../../_phosphorus/_types";
import ButtonIcon from "../../_phosphorus/buttons/ButtonIcon";
import { ButtonVariant, ButtonIconProps } from "../../_phosphorus/buttons/_types";

/** An opinionated button used in the app menu for quick actions */
export default function QuickActionButton(props: ButtonIconProps) {
  return (
    <ButtonIcon
      square={false}
      variant={ButtonVariant.Filled}
      size={ComponentSize.XL}
      radius={ComponentSize.LG}
      backgroundColor='white'
      color='black'
      height={50}
      width='100%'
      withMotion={!props.disabled}
      withShadow={!props.disabled}
      {...props}
    />
  )
}