import EmptyStateImage from "../../assets/images/no-favourites.png";

import { useTranslation } from "react-i18next";
import Header from "../../components/layout/Header";
import PageContainer from "../../components/layout/PageContainer";
import SessionNavbar, { SessionPage } from "../../components/layout/nav/SessionNavbar";
import Title from "../../components/text/Title";
import { useNavigate } from "react-router-dom";
import { SpotifySession } from "../../func/models/sessions/spotifySession";
import { useEffect, useState } from "react";
import { ClientSession } from "../../func/models/sessions/clientSession";
import PasscodeInput, { readPartyCode } from "../../components/inputs/text/PasscodeInput";
import { Room, findRoomByID, joinRoom } from "../../func/models/room";
import SpotifySessionBadge from "../../components/display/SpotifySessionBadge";
import { Center, Space } from "@mantine/core";
import { MixListCard } from "../../components/inputs/buttons/MixCards";
import { Mix } from "../../func/models/mix";
import ButtonIcon from "../../components/_phosphorus/buttons/ButtonIcon";
import { IconStarFilled } from "@tabler/icons-react";
import { ButtonVariant } from "../../components/_phosphorus/buttons/_types";
import Text from "../../components/_phosphorus/text/FormattedText";
import { TextAlign } from "../../components/_phosphorus/text/_types";
import InvertableImage from "../../components/display/InvertableImage";
import { ComponentSize } from "../../components/_phosphorus/_types";

/** The homepage shown when the user has an active Spotify session. 
 * Shown in place of the join page 
 */
export default function SessionJoinPage() {

  // Hooks
  const { t } = useTranslation('sessionJoin');
  const navigate = useNavigate();

  // States
  const [spotifySession, setSpotifySession] = useState<SpotifySession>(null);
  const [favouriteParties, setFavouriteParties] = useState<Mix[]>([]);



  /** Check the client has a valid Spotify session. If not, 
   * redirect them away from this screen.
   */
  async function detectSession() {
    let spotifySession = await new SpotifySession().deserialise();
    let code = readPartyCode();
    if (!spotifySession) {
      navigate(`/${!code ? "" : `?code=${code}`}`);
      return
    };

    setSpotifySession(spotifySession);
  }

  /** Join a party using the provided code or ID and the user's Spotify displayName */
  async function join(codeOrID: string) {
    if (!spotifySession) return;
    await joinRoom(codeOrID, spotifySession.displayName || spotifySession.id, spotifySession.id);
    navigate("/mix/home");
  }

  /** Remove a room from the list of favourites */
  async function unfavouriteRoom(id: string) {
    await spotifySession.removeFavouriteRoom(id);
    await detectSession();
  }

  /** Get the party information about each room */
  async function retrieveFavouriteRooms() {
    if (!spotifySession) return;

    let parties: Mix[] = [];
    for (const f of spotifySession.favouriteRooms) {
      let room = await findRoomByID(f);
      if (!room) continue;
      let party = await room.retrieveRoomInfo();
      if (party) parties.push(party);
    }
    setFavouriteParties(parties);
  }

  useEffect(() => {
    detectSession();

    /** Remove the ClientSession from the sessionStorage */
    new ClientSession().remove();
  }, []);

  // Update the user's favourite rooms whenever their Spotify session updates
  // This should only be once in the lifespan of the page
  useEffect(() => { retrieveFavouriteRooms() }, [spotifySession]);



  return (
    <>
      <PageContainer
        navbar={<SessionNavbar selectedPage={SessionPage.Join} />}
      >
        <Header>
          <Title>{t("screens.code.title", { ns: "join" })}</Title>

          <SpotifySessionBadge session={spotifySession} />
        </Header>

        {/* Digit input & button */}
        {!spotifySession ? null :
          <PasscodeInput onRoomFound={join} />
        }

        <Space h="sm" />

        <Title order={2}>{t("favourites.title")}</Title>



        {favouriteParties.length > 0 ? favouriteParties.map(party =>
          <MixListCard
            key={party.id}
            id={party.id}
            name={party.name}
            description={t("favourites.cards.host", { host: party.host.username })}
            onClick={join}
            secondaryButtons={[
              <ButtonIcon
                key={0}
                variant={ButtonVariant.Subtle}
                color="black"
                onClick={(event: any) => { event.stopPropagation(); unfavouriteRoom(party.id) }}
              ><IconStarFilled /></ButtonIcon>
            ]}
          />
        ) :
          <Center>
            <InvertableImage
              src={EmptyStateImage}
              maw={250}
            />
          </Center>
        }

        <Text
          align={TextAlign.Center}
          size={ComponentSize.SM}
        >{t("favourites.prompt")}</Text>
      </PageContainer>
    </>
  )
}