import { SPOTIFY } from "../contexts/consts";
import { requestClientCredentials } from "./spotifyAuth";

async function spotifyRequest(
  endpoint: string,
  method: 'GET' | 'PUT' | 'POST' | 'DELETE',
  accessToken: string,
  body?: string,
  contentType?: string,
) { 
  return await fetch(`${ SPOTIFY.API_DOMAIN }${ endpoint }`, {
    method: method,
    body: body !== '' ? body : null,
    headers: { 
      'Authorization': `Bearer ${ accessToken }`,
      'Content-Type': contentType || 'application/json',
    }
  });
}

/** A fetching request to be used exclusively by guests. All host requests will
 * need to go through the Houseparty API.
 */
export async function spotifyFetch(
  endpoint: string,
  method: 'GET' | 'PUT' | 'POST' | 'DELETE',
  accessToken: string,
  body?: string,
  contentType?: string,
  abortOnUnauthorised?: boolean,
) { 
  var response = await spotifyRequest(endpoint, method, accessToken, body, contentType);
  if (!response.ok) { 
    if (response.status !== 401) { 
      console.error(`Spotify request failed`, endpoint, response.status);
      return response;
    }

    /** Fail if supposed to */
    if (abortOnUnauthorised) { 
      console.error('Spotify request failed due to unauthorisation', endpoint, response.status);
      return response;
    }

    /** This token has presumably expired, try again and retry
     * - Guests don't have access to a refresh token - they need entirely new credentials
     * - Hosts are signed in with a longer-term token, but their refresh token is not stored client side
     */
    const { accessToken } = await requestClientCredentials();

    response = await spotifyRequest(endpoint, method, accessToken, body, contentType);
    if (!response.ok) { 
      console.error('Second attempt: Spotify request failed', endpoint, response.status);
      return response;
    }
  }
  return response;
}