import { MantineTheme } from "@mantine/core";

export const DEFAULT_GRADIENT = {
  colors: ['orange', 'salmon', 'violet'],
  deg: 90,
}

export const
  BLACK_PALETTE = ['#111116', '#111116', '#111116', '#111116', '#111116', '#111116', '#111116', '#111116', '#111116', '#111116'],
  WHITE_PALETTE = ['#F1F0F0', '#F1F0F0', '#F1F0F0', '#F1F0F0', '#F1F0F0', '#F1F0F0', '#F1F0F0', '#F1F0F0', '#F1F0F0', '#F1F0F0'],
  SPOTIFY_GREEN = ['#1DB954', '#1DB954', '#1DB954', '#1DB954', '#1DB954', '#1DB954', '#1DB954', '#1DB954', '#1DB954', '#1DB954'];

export function defaultGradient(theme: MantineTheme) {
  return `linear-gradient(${DEFAULT_GRADIENT.deg
    }deg, ${DEFAULT_GRADIENT.colors.map((i) => {
      return ` ${theme.colors[i][4]}`
    })
    })`;
}