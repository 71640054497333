import { NavigateFunction } from "react-router-dom";
import { SPOTIFY } from "../contexts/consts";
import generateRandomString from "../utilities/random";
import { spotifyFetch } from "./spotifyFetch";
import { Buffer } from "buffer";

export type RedirectParams = {
  page: string;
  params?: { [key: string]: string };
}

/** Initiates a Spotify sign-in session, allowing the user to sign in.
 * @param redirect If supplied, will redirect to this page after the sign in
 */
export function spotifySignIn(redirect: RedirectParams) {
  // Create & save a state object to check against later
  let state = generateRandomString(16);
  sessionStorage.setItem("authState", state);

  // Save the redirect parameters to the sessionStorage so we can retrieve it later
  sessionStorage.setItem("redirect", JSON.stringify({
    page: redirect.page,
    params: redirect.params,
  }));

  // Begin the sign in process 
  window.location.href = `${SPOTIFY.DOMAIN}authorize?` +
    //@ts-ignore
    new URLSearchParams({
      response_type: "code",
      client_id: SPOTIFY.CLIENT_ID,
      scope: SPOTIFY.SCOPES,
      redirect_uri: SPOTIFY.REDIRECT_URI,
      state: state,
    }).toString();
}

export async function spotifyAuthCallback(
  searchParams: URLSearchParams
) {

  // Collect values
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  let storedState = sessionStorage.getItem("authState");
  let redirect: RedirectParams = JSON.parse(sessionStorage.getItem("redirect"));

  // Remove saved values
  sessionStorage.removeItem("authState");
  sessionStorage.removeItem("redirect");

  // Check the auth state to ensure it exists and is valid. If not, 
  // we don't want to be here.
  if (!code || !state || storedState !== state) return "invalidCreds";

  /** Quickly make a call to the Spotify API to retrieve information about
   * this user. This information will then be sent to our API to help create
   * the room, but needs to be done here first.
   */
  let credentials = await requestAuthCredentials(code);
  if (!credentials) return "invalidCreds";

  let response = await spotifyFetch("me", "GET", credentials.accessToken, null, null, true);
  if (!response.ok) return "error";

  const userInfo = await response.json();

  // Return useful information
  return ({
    redirect: redirect,
    session: {
      userID: userInfo.id,
      displayName: userInfo.display_name,
      profileImageURL: userInfo.images.length > 0 ? userInfo.images[0].url : null,
      credentials: credentials,
    }
  });
}

export async function requestAuthCredentials(code: string) {
  let response = await fetch(`${SPOTIFY.DOMAIN}api/token`, {
    method: "POST",
    body: new URLSearchParams({
      grant_type: "authorization_code",
      code: code,
      redirect_uri: SPOTIFY.REDIRECT_URI,
    }).toString(),
    headers: {
      "Authorization": `Basic ${(new Buffer(SPOTIFY.CLIENT_ID + ":" + SPOTIFY.CLIENT_SECRET).toString("base64"))}`,
      "Content-Type": "application/x-www-form-urlencoded",
    }
  });
  if (!response.ok) {
    console.error("Spotify auth credential request failed, error", response.status);
    return;
  }

  const result = await response.json();
  return ({ accessToken: result.access_token, refreshToken: result.refresh_token });
}

export async function requestClientCredentials() {
  let response = await fetch(`${SPOTIFY.DOMAIN}api/token`, {
    method: "POST",
    body: new URLSearchParams({
      grant_type: "client_credentials",
    }).toString(),
    headers: {
      "Authorization": `Basic ${(new Buffer(SPOTIFY.CLIENT_ID + ":" + SPOTIFY.CLIENT_SECRET).toString("base64"))}`,
      "Content-Type": "application/x-www-form-urlencoded",
    }
  });
  if (!response.ok) {
    console.error("Spotify client credential request failed, error", response.status);
    return;
  }

  const result = await response.json();
  return ({ accessToken: result.access_token });
}