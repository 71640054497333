import { NavigateFunction } from "react-router-dom";
import { IRoomContext } from "../contexts/roomContext";
import { ClientSession } from "./sessions/clientSession";
import { TrackMeta } from "./spotify/track";
import { User } from "./user";

export class Mix {
  /** ID of this mix */
  id: string;
  /** Information about the host */
  host: User;
  /** Information about the guests at this mix */
  guests: User[];

  /** The name of this mix */
  name: string;
  /** A short description of this mix */
  description: string;
  /** The 6-digit passcode for this mix */
  password: string;

  /** The voting point of this mix */
  votingPoint: number;
  /** Whether explicit songs are allowed */
  isExplicitAllowed: boolean;
  /** Whether the password is shown on guests' devices */
  isPasswordPublic: boolean;

  /** Content of the mix's queue */
  queue: TrackMeta[];

  constructor(
    id: string,
    host: User,
    guests: User[],
    name: string,
    description: string,
    password: string,
    votingPoint: number,
    isExplicitAllowed: boolean,
    isPasswordPublic: boolean,
    queue: TrackMeta[],
  ) {
    this.id = id;
    this.host = host;
    this.guests = guests;
    this.name = name;
    this.description = description;
    this.password = password;
    this.votingPoint = votingPoint;
    this.isExplicitAllowed = isExplicitAllowed;
    this.isPasswordPublic = isPasswordPublic;
    this.queue = queue;
  }
}

export function checkMix(
  roomContext: IRoomContext,
  navigate: NavigateFunction,
) {
  let clientSession = new ClientSession();
  clientSession.deserialise();

  /** If the party loads in, but doesn't have an ID,
   * we can assume its setup process is incomplete.
   * For hosts, we'll redirect them to a setup screen,
   * and for guests we'll give them an error message
   * to explain what's going on
   */
  if (roomContext.party.id && !roomContext.party.name) {
    if (clientSession.isHost) {
      /** Navigate to the host setup screen */
      navigate('/mix/setup');
    } else {
      navigate('/');
    }
    return false;
  }

  return true;
}

export function findUserInMix(party: Mix, id: string) {
  let user = party.guests.find((i) => i.id === id);
  if (!user && party.host.id === id) user = party.host;

  return user;
}