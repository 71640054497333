import { Avatar, Badge, MantineTheme, Sx } from "@mantine/core";
import { SpotifySession } from "../../func/models/sessions/spotifySession"

type Props = {
  session: SpotifySession;
}

/** A simple component to display the badge for a Spotify session */
export default function SpotifySessionBadge(props: Props) {

  const badgeStyle: Sx = (theme: MantineTheme) => ({
    alignSelf: "flex-start",
    textTransform: "none",
    backgroundColor: theme.black,
    color: theme.white,
  })



  return (
    <>
      {!props.session ? null :
        <Badge
          pl={0}
          size="xl"
          radius="xl"
          leftSection={<Avatar
            alt="Spotify user avatar"
            size={30}
            mr={5}
            radius="xl"
            src={props.session.profileImageURL}
          />}
          sx={badgeStyle}
        >
          {props.session.displayName}
        </Badge>
      }
    </>
  )
}