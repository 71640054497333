import placeholderImage from "../../../assets/images/appicon.png";
import { Card, Group, Image, MantineTheme, Paper, Stack, Sx, } from "@mantine/core";
import Text from "../../_phosphorus/text/FormattedText";
import { motion, useReducedMotion } from "framer-motion";
import { ReactNode } from "react";



type Props = {
  id: string;
  name: string;
  description: string;
  onClick: Function;
  imageURL?: string;
  secondaryButtons?: ReactNode[];     // Not yet implemented into MixGridCard
}

export function MixGridCard(props: Props) {

  // Hooks
  const isReducedMotion = useReducedMotion();



  // Styles
  const imageStyle: Sx = (theme: MantineTheme) => ({
    aspectRatio: "1",
    marginBottom: theme.spacing.sm,

    "& > *, & > * > *": {
      height: "100%",
      width: "100%",
    },
  });

  return (
    <motion.div
      whileHover={{ scale: isReducedMotion ? 1 : 1.05 }}
      whileTap={{ scale: isReducedMotion ? 1 : 1.025 }}
      transition={{ ease: "backOut" }}
    >
      <Card
        shadow="md"
        padding="sm"
        radius="lg"
        onClick={() => { props.onClick(props.id) }}
        style={{ cursor: "pointer" }}
      >
        <Image
          src={props.imageURL || placeholderImage}
          withPlaceholder
          alt="Mix thumbnail image"
          radius="sm"
          sx={imageStyle}
        />

        <Text
          weight={800}
          size={18}
        >
          {props.name}
        </Text>

        <Text
          size={14}
          color="dimmed"
        >
          {props.description}
        </Text>
      </Card>
    </motion.div>
  )
}

export function MixListCard(props: Props) {

  // Hooks
  const isReducedMotion = useReducedMotion();



  return (

    <motion.div
      whileHover={{ scale: isReducedMotion ? 1 : 1.05 }}
      whileTap={{ scale: isReducedMotion ? 1 : 1.025 }}
      transition={{ ease: "backOut" }}
    >
      <Paper
        shadow="md"
        p="sm"
        radius="lg"
        onClick={() => { props.onClick(props.id) }}
        style={{
          cursor: "pointer",
        }}
      >
        <Group spacing="md">
          <Image
            src={props.imageURL || placeholderImage}
            withPlaceholder
            alt="Mix thumbnail image"
            radius="sm"
            width={80}
          />

          <Stack spacing={5} style={{ flex: 1 }}>
            <Text
              weight={800}
              size={18}
            >
              {props.name}
            </Text>

            <Text
              size={14}
              color="dimmed"
            >
              {props.description}
            </Text>
          </Stack>

          {!props.secondaryButtons ? null :
            <Group>
              {props.secondaryButtons}
            </Group>
          }
        </Group>
      </Paper>
    </motion.div>
  )
}