import EmptyStateImage from "../../assets/images/no-mixes.png";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpotifySession } from "../../func/models/sessions/spotifySession";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../components/layout/PageContainer";
import SessionNavbar, { SessionPage } from "../../components/layout/nav/SessionNavbar";
import Header from "../../components/layout/Header";
import Title from "../../components/text/Title";
import SpotifySessionBadge from "../../components/display/SpotifySessionBadge";
import { Center, SimpleGrid, Stack } from "@mantine/core";
import { Mix } from "../../func/models/mix";
import { Room, findRoomByHostID, joinRoom } from "../../func/models/room";
import Button from "../../components/_phosphorus/buttons/Button";
import { ButtonVariant } from "../../components/_phosphorus/buttons/_types";
import { MixGridCard } from "../../components/inputs/buttons/MixCards";
import InvertableImage from "../../components/display/InvertableImage";

export default function SessionMixesPage() {

  // Hooks
  const { t } = useTranslation('sessionMixes');
  const navigate = useNavigate();

  // States
  const [spotifySession, setSpotifySession] = useState<SpotifySession>(null);
  const [mixes, setMixes] = useState<Mix[]>([]);



  /** Check the client has a valid Spotify session. If not, 
   * redirect them away from this screen.
   */
  async function detectSession() {
    let spotifySession = await new SpotifySession().deserialise();
    if (!spotifySession) navigate("/");
    else setSpotifySession(spotifySession);
  }

  /** Retrieve this user's mixes, if they have any. As of update
   * v1.1, this will just return a single result, but this will 
   * eventually be updated to accommodate for more in 1.2
   */
  async function retrieveMixes() {
    if (!spotifySession) return;

    let room = await findRoomByHostID(spotifySession.id);
    if (!room) return;

    let party = await room.retrieveRoomInfo();
    if (!party) return;
    setMixes([party]);
  }

  /** Join a mix with the given ID */
  async function joinMix(id: string) {
    await joinRoom(id, spotifySession.displayName || spotifySession.id, spotifySession.id);
    navigate("/mix/home");
  }

  /** Create a new mix and join it */
  async function createMix() {
    if (!spotifySession) return;

    // As of v1.1, the user cannot have multiple parties and Cloudflare can be wonky
    // sometimes. As a result, we need to double-check the user does not have a party
    // and join them to their existing party if they do.
    // This is an issue we should never encounter in production, it just has to do 
    // with how Cloudflare's DurableObjects function.
    let room = await findRoomByHostID(spotifySession.id);
    if (room) await joinRoom(room.id, spotifySession.displayName || spotifySession.id, spotifySession.id);
    else {
      // Create a new room and join it
      room = new Room("", "", spotifySession.id, "");
      await room.create();
    }

    // Join the room
    await joinRoom(room.code, spotifySession.displayName || spotifySession.id, spotifySession.id);

    // Navigate to the mix screen
    navigate("/mix/home");
  }

  useEffect(() => { detectSession() }, []);
  useEffect(() => { retrieveMixes() }, [spotifySession]);


  return (
    <>
      <PageContainer
        navbar={<SessionNavbar selectedPage={SessionPage.Mixes} />}
      >
        <Header>
          <Title>{t("title")}</Title>

          <SpotifySessionBadge session={spotifySession} />
        </Header>

        {mixes.length === 0 ?
          <Stack>
            <Center>
              <InvertableImage
                src={EmptyStateImage}
                maw={250}
              />
            </Center>
            <Button
              variant={ButtonVariant.Filled}
              onClick={createMix}
            >
              {t("empty.button")}
            </Button>
          </Stack>
          :
          // Grid of mix items
          <SimpleGrid
            cols={2}
            spacing="sm"
          >
            {mixes.map((mix) =>
              <MixGridCard
                id={mix.id}
                name={mix.name}
                description={mix.description}
                onClick={joinMix}
                key={mix.id}
              />
            )}
          </SimpleGrid>
        }
      </PageContainer >
    </>
  )
}