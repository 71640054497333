import { Thumbnail } from "./thumbnail";
import { Track } from "./track";

/** A low-level class that stores important metadata about
 * an Album on Spotify. This class does not contain all
 * the metadata returned by Spotify; only what we consider
 * useful for Realmix.
 */
export class Album { 
  id: string;
  name: string;
  uri: string;
  releaseDate: string;
  thumbnail: Thumbnail;

  constructor ( 
    id: string,
    name: string,
    uri: string,
    releaseDate: string,
    thumbnail: Thumbnail,
  ) { 
    this.id = id;
    this.name = name;
    this.uri = uri;
    this.releaseDate = releaseDate;
    this.thumbnail = thumbnail;
  }
}

/** An extension of the basic Album class that also contains a 
 * list of all the contained Tracks.
 */
export class AlbumWithTracks extends Album {
  tracks: Track[];

  constructor(
    id: string,
    name: string,
    uri: string,
    releaseDate: string,
    thumbnail: Thumbnail,
    tracks: Track[],
  ) { 
    super(id, name, uri, releaseDate, thumbnail);
    this.tracks = tracks;
  }
}