import { Group, Image, Loader, MantineTheme, Stack, Sx } from "@mantine/core";
import { Track } from "../../../func/models/spotify/track"
import MetaRow from "./MetaRow";
import Text from "../../_phosphorus/text/FormattedText";
import { IconDotsVertical, IconExplicit, IconPlus } from "@tabler/icons-react";
import ButtonBase from "../../_phosphorus/buttons/ButtonBase";
import { useContext, useEffect, useState } from "react";
import { RoomContext } from "../../../func/contexts/roomContext";
import { addTracks } from "../../../func/websockets/messageSender";
import { ButtonVariant } from "../../_phosphorus/buttons/_types";
import Button from "../../_phosphorus/buttons/Button";
import { ComponentSize } from "../../_phosphorus/_types";
import ButtonIcon from "../../_phosphorus/buttons/ButtonIcon";
import { useTranslation } from "react-i18next";

type Props = {
  track: Track;
  hideSpotifyLogo?: boolean;
  hideImage?: boolean;
}

export function TrackSmall(props: Props) {

  // Contexts
  const roomContext = useContext(RoomContext);

  // States
  const [isInPlaylist, setIsInPlaylist] = useState(false);
  const [loading, setLoading] = useState(false);

  /** Add a track to the playlist */
  async function addTrack() {
    if (isInPlaylist || loading) return;
    setLoading(true);
    addTracks(
      [props.track.uri],
      false,
    );
  }

  /** Check if this track is already in the playlist */
  useEffect(() => {
    if (roomContext.party.queue.find((t) => t.id === props.track.id)) setIsInPlaylist(true);
    setLoading(false);
  }, [roomContext]);



  return (
    <ButtonBase
      onClick={() => addTrack()}
      color="black"
      variant={ButtonVariant.Subtle}
      height="fit-content"
      disabled={isInPlaylist}
    >
      <MetaRow
        title={
          <Group>
            <Text weight={800} lineClamp={1}>
              {props.track.name}
            </Text>
            {props.track.isExplicit ? <IconExplicit size={20} /> : null}
          </Group>
        }
        subtitle={`${props.track.artists.reduce((acc, cur) => acc + cur.name + ", ", "").slice(0, -2)
          } • ${props.track.album.name}`}
        image={props.hideImage ? null : props.track.album.thumbnail.url}
        spotifyLogo={props.hideSpotifyLogo ? null : {
          onClick: () => window.open(props.track.uri, "_blank"),
          followTheme: true,
        }}
        rightButtons={loading ?
          <Loader size="sm" color="black" />
          : isInPlaylist ? null :
            <IconPlus size={18} />
        }
        style={{ padding: "2px 0px" }}
      />
    </ButtonBase>
  )
}

export function TrackLarge(props: Props) {

  // Contexts
  const roomContext = useContext(RoomContext);

  // Hooks
  const { t } = useTranslation("mixAdd");

  // States
  const [isInPlaylist, setIsInPlaylist] = useState(false);
  const [loading, setLoading] = useState(false);



  /** Add a track to the playlist */
  async function addTrack() {
    if (isInPlaylist || loading) return;
    setLoading(true);
    addTracks(
      [props.track.uri],
      false,
    );
  }

  /** Check if this track is already in the playlist */
  useEffect(() => {
    if (roomContext.party.queue.find((t) => t.id === props.track.id)) setIsInPlaylist(true);
    setLoading(false);
  }, [roomContext]);



  // Styles
  const containerStyle: Sx = (theme: MantineTheme) => ({
    backgroundColor: theme.colorScheme === "dark" ? `${theme.black}20` : theme.black,
    borderRadius: theme.radius.lg,
    padding: theme.spacing.xs,
    color: theme.colorScheme === "dark" ? theme.black : theme.white,
    flexWrap: "nowrap",
  });
  const stackStyle: Sx = (theme: MantineTheme) => ({
    flex: 1,
    gap: theme.spacing.xs,
  });
  const buttonGroupStyle: Sx = (theme: MantineTheme) => ({
    gap: theme.spacing.sm,
    flexWrap: 'nowrap',
    "& :first-of-type": {
      flexGrow: 1,
    }
  });



  return (
    <>
      <Group sx={containerStyle}>
        {props.hideImage ? null :
          <Image
            src={props.track.album.thumbnail.url}
            width={80}
          />
        }

        <Stack sx={stackStyle}>
          <MetaRow
            title={
              <Group>
                <Text weight={800} lineClamp={1}>
                  {props.track.name}
                </Text>
                {props.track.isExplicit ? <IconExplicit size={20} /> : null}
              </Group>
            }
            subtitle={`${props.track.artists.reduce((acc, cur) => acc + cur.name + ", ", "").slice(0, -2)
              } • ${props.track.album.name}`}
            spotifyLogo={props.hideSpotifyLogo ? null : {
              onClick: () => window.open(props.track.uri, "_blank"),
              followTheme: false,
              default: "dark",
            }}
          />

          <Group sx={buttonGroupStyle}>
            <Button
              variant={ButtonVariant.Filled}
              size={ComponentSize.SM}
              style={{ flexGrow: 1 }}
              onClick={() => addTrack()}
              disabled={isInPlaylist || loading}
            >
              {loading ?
                <Loader color="white" size="sm" />
                :
                t("buttons.add")
              }
            </Button>

            <ButtonIcon
              radius={ComponentSize.MD}
              size={ComponentSize.SM}
              color="permaWhite"
              backgroundColor="permaWhite"
              variant={ButtonVariant.Subtle}
            ><IconDotsVertical /></ButtonIcon>
          </Group>
        </Stack>
      </Group>
    </>
  )
}