export const SPOTIFY = {
  DOMAIN:         'https://accounts.spotify.com/',
  API_DOMAIN:     'https://api.spotify.com/v1/',
  CLIENT_ID:      'a64aa1eb49394b97915f71cb10f4c0b0',
  CLIENT_SECRET:  '19ac025a9a8349f0a325570cdef6dfe2',

  /** This REDIRECT_URI const has been moved to an environment variable to
   * avoic conflicts on the development and production servers. Cloudflare
   * contains the proper redirect_uri for production, and they will automatically
   * switch upon pushing to prod.
   * 
   * DEV: http://localhost:3000/auth/
   * PROD: https://app.realmix.app/auth/
   */
  REDIRECT_URI: process.env.REACT_APP_SPOTIFY_REDIRECT_URI,

  SCOPES: [
    'user-read-email',
    'user-library-read',                // Access to library
    'playlist-modify-public',           // Create & edit playlists
    'playlist-read-private',
    'playlist-modify-private',
  ]
}

// [v1.1] These variable have been migrated to env vars so that we don't 
// accidentally mess with them during production or preview branches.
// This can be changed in the .env.development file.
export const API_DOMAIN = process.env.REACT_APP_WORKER_API;
export const WS_DOMAIN = process.env.REACT_APP_WORKER_WS;

// export const API_DOMAIN = 'http://localhost:8787/';
// export const WS_DOMAIN  = 'ws://localhost:8787/';
// export const API_DOMAIN = 'https://houseparty-v3-worker.zaccomode.workers.dev/';
// export const WS_DOMAIN  = 'wss://houseparty-v3-worker.zaccomode.workers.dev/';
