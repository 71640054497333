import { randomId } from "@mantine/hooks";
import { API_DOMAIN } from "../contexts/consts";
import { ClientSession } from "./sessions/clientSession";
import { Mix } from "./mix";
import { User } from "./user";
import { TrackMeta } from "./spotify/track";
import { Album } from "./spotify/album";
import { Thumbnail } from "./spotify/thumbnail";
import { Artist } from "./spotify/artist";

export class Room {
  /** The DurableObjectId */
  id: string;
  /** 6-digit entry code for this room */
  code: string;
  /** Spotify-assigned ID of the host */
  hostID: string;
  /** Spotify-created ID of this room's associated playlist */
  playlistID: string;

  constructor(
    id: string,
    code: string,
    hostID: string,
    playlistID: string,
  ) {
    this.id = id;
    this.code = code;
    this.hostID = hostID;
    this.playlistID = playlistID;
  }

  async create() {
    let response = await fetch(`${API_DOMAIN}do/room/`, {
      method: "PUT",
      body: JSON.stringify({
        hostID: this.hostID,
      }),
    });
    if (!response.ok) {
      console.log('Failed to create room', response.status);
      return;
    }

    /** Refresh this room's information with the response */
    let room: Room = await response.json();
    this.id = room.id;
    this.code = room.code;
    this.hostID = room.hostID;
  }

  async retrieveRoomInfo() {
    let response: Response;
    try {
      response = await fetch(`${API_DOMAIN}do/room/name/${this.id}/info`);
      if (!response.ok) {
        console.log("Failed to retrieve room information");
        return;
      }
    } catch {
      console.log("Failed to retrieve room information");
      return;
    }

    // Parse response
    let info: any = await response.json();
    let partyInfo: Mix = info.party;


    const party = new Mix(
      partyInfo.id,
      partyInfo.host,
      partyInfo.guests.map((g) => new User(
        g.id,
        g.username,
        g.isHost,
      )),
      partyInfo.name,
      partyInfo.description,
      partyInfo.password,
      partyInfo.votingPoint,
      partyInfo.isExplicitAllowed,
      partyInfo.isPasswordPublic,
      partyInfo.queue.map((t) => new TrackMeta(
        t.id,
        t.name,
        t.uri,
        new Album(
          t.album.id,
          t.album.name,
          t.album.uri,
          t.album.releaseDate,
          new Thumbnail(
            t.album.thumbnail.url,
            t.album.thumbnail.height,
            t.album.thumbnail.width,
          ),
        ),
        t.artists.map((a) => new Artist(
          a.id,
          a.name,
          a.uri,
        )),
        t.duration,
        t.isExplicit,
        t.userID,
        t.votedAgainst
      )),
    );

    return party;
  }
}

/** Attempts to find a room given the host's ID */
export async function findRoomByHostID(id: string) {
  let response = await fetch(`${API_DOMAIN}do/room/host/${id}`);
  if (!response.ok) return;

  let roomInfo: Room = await response.json();
  return new Room(
    roomInfo.id,
    roomInfo.code,
    roomInfo.hostID,
    roomInfo.playlistID,
  );
}

/** Attempts to find a room given the passcode */
export async function findRoomByPasscode(code: string) {
  let response = await fetch(`${API_DOMAIN}do/room/passcode/${code}`);
  if (!response.ok) return;

  let roomInfo: Room = await response.json();
  return new Room(
    roomInfo.id,
    roomInfo.code,
    roomInfo.hostID,
    roomInfo.playlistID,
  );
}

/** Attempts to find a room given the ID */
export async function findRoomByID(id: string) {
  let response = await fetch(`${API_DOMAIN}do/room/id/${id}`);
  if (!response.ok) return;

  let roomInfo: Room = await response.json();
  return new Room(
    roomInfo.id,
    roomInfo.code,
    roomInfo.hostID,
    roomInfo.playlistID,
  );
}

/** Creates a ClientSession so that a user can join a party. 
 * @param codeOrID The code or ID for the party. Will attempt to find a party in that order
 * @param username The username to give this user
 * @param id A possible pre-defined identifier for this user. Often used to allow Hosts to join as themselves 
*/
export async function joinRoom(
  codeOrID: string,
  username: string,
  id?: string,
) {
  let room = await findRoomByPasscode(codeOrID) || await findRoomByID(codeOrID);
  if (!room) return false;

  /** Create a new ClientSession with the information we have,
   * then serialise it for later
   */
  let clientSession: ClientSession;
  try {
    clientSession = new ClientSession(
      id || crypto.randomUUID(),      /** This is random because it's not the host's ID */
      username,
      room.id,
      room.hostID === id,
    );
  } catch (e) {
    /** Because iOS is a fucking tragic platform, crypto.randomUUID() actually
     * breaks after iOS 15. This means that the above function will not work, 
     * but UUIDs are just better so I have this as a backup.
     * 
     * Thank you Apple for creating a giant sack of shit platform. If you could 
     * abide by everyone else's standards, I wouldn't have to debug these stupid
     * fucking bugs.
     * - Isaac
     */
    clientSession = new ClientSession(
      id || randomId(),
      username,
      room.id,
      room.hostID === id,
    );
  }
  clientSession.serialise();
  return true;
}