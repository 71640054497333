import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      common:           require("./locales/en/common.json"),
      join:             require("./locales/en/pages/join.json"),
      
      sessionJoin:      require("./locales/en/pages/session/join.json"),
      sessionMixes:     require("./locales/en/pages/session/mixes.json"),
      sessionSettings:  require("./locales/en/pages/session/settings.json"),
      
      mixHome:          require("./locales/en/pages/mix/home.json"),
      mixSettings:      require("./locales/en/pages/mix/settings.json"),
      mixAdd:           require("./locales/en/pages/mix/add.json"),
      mixSetup:         require("./locales/en/pages/mix/setup.json"),

      pwa:              require("./locales/en/flows/pwa.json"),

      navbar:           require("./locales/en/elements/navbar.json"),
    },
  },
  ns: [
    "common",
    "join",
    "mixSetup", 
    "sessionJoin",
    "sessionMixes",
    "sessionSettings",
    "mixHome",
    "mixSettings",
    "mixSearch",
    "pwa",
    "navbar",
  ],
  defaultNS: "common"
});

i18n.languages = ["en"];

export default i18n;