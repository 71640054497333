import plusJakartaReg from './assets/fonts/PlusJakartaSans-Regular.ttf';
import plusJakartaItalic from './assets/fonts/PlusJakartaSans-Italic.ttf';
import plusJakartaExtraBold from './assets/fonts/PlusJakartaSans-ExtraBold.ttf';
import plusJakartaExtraBoldItalic from './assets/fonts/PlusJakartaSans-ExtraBoldItalic.ttf';
import archivoBlack from './assets/fonts/Archivo_Expanded-Black.ttf';
import jetBrainsExtraBold from './assets/fonts/JetBrainsMono-ExtraBold.ttf';

import { Global, MantineProvider } from "@mantine/core";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useColorScheme } from '@mantine/hooks';
import JoinPage from './pages/join';
import AuthPage from './pages/signIn';
import MixHomePage from './pages/mix/home';
import { useState } from 'react';
import { Mix } from './func/models/mix';
import { RoomContext } from './func/contexts/roomContext';
import MixSetupPage from './pages/mix/setup';
import MixAddPage from './pages/mix/add';
import MixSettingsPage from './pages/mix/settings';
import { Notifications } from '@mantine/notifications';
import { BLACK_PALETTE, SPOTIFY_GREEN, WHITE_PALETTE } from './components/_colors';
import SessionJoinPage from './pages/session/join';
import SessionMixesPage from './pages/session/mixes';
import SessionSettingsPage from './pages/session/settings';

function App() {

  /** Hooks */
  const colorScheme = useColorScheme();

  /** Contexts */
  const [party, setParty] = useState(new Mix('', null, [], '', '', '', 0, false, false, []));
  const roomContext = {
    party: party,
    setParty: setParty,
  }

  return (
    <>
      <Global
        styles={[
          {
            '@font-face': {
              fontFamily: 'Plus Jakarta Sans',
              src: `url('${plusJakartaReg}') format("woff2")`,
              fontWeight: 400,
              fontStyle: 'normal',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Plus Jakarta Sans',
              src: `url('${plusJakartaItalic}') format("woff2")`,
              fontWeight: 400,
              fontStyle: 'italic',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Plus Jakarta Sans',
              src: `url('${plusJakartaExtraBold}') format("woff2")`,
              fontWeight: 800,
              fontStyle: 'normal',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Plus Jakarta Sans',
              src: `url('${plusJakartaExtraBoldItalic}') format("woff2")`,
              fontWeight: 800,
              fontStyle: 'italic',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Archivo',
              src: `url('${archivoBlack}') format("woff2")`,
              fontWeight: 800,
              fontStyle: 'normal',
            },
          },
          {
            '@font-face': {
              fontFamily: 'JetBrains Mono',
              src: `url('${jetBrainsExtraBold}') format("woff2")`,
              fontWeight: 800,
              fontStyle: 'normal',
            },
          },
        ]}
      />

      <BrowserRouter>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            fontFamily: 'Plus Jakarta Sans, sans-serif',
            fontFamilyMonospace: 'JetBrains Mono, Courier New, monospace',
            headings: { fontFamily: 'Archivo, sans-serif', fontWeight: 800 },
            colorScheme: colorScheme,
            colors: {
              salmon: ['#ffe5e7', '#fdb7bc', '#f68991', '#f15b65', '#F6303E', '#d21521', '#a40e19', '#760811', '#480309', '#1e0001'],
              //@ts-ignore
              spotifyGreen: SPOTIFY_GREEN,
              //@ts-ignore
              black: colorScheme === 'light' ? BLACK_PALETTE : WHITE_PALETTE,
              //@ts-ignore
              white: colorScheme === 'light' ? WHITE_PALETTE : BLACK_PALETTE,
              //@ts-ignore
              permaBlack: BLACK_PALETTE,
              //@ts-ignore
              permaWhite: WHITE_PALETTE,
            },
            black: colorScheme === 'light' ? BLACK_PALETTE[0] : WHITE_PALETTE[0],
            white: colorScheme === 'light' ? WHITE_PALETTE[0] : BLACK_PALETTE[0],
            primaryColor: 'salmon',
            primaryShade: 4,
            defaultRadius: 'md',
          }}
        >
          <Notifications
            position='top-center'
            zIndex={9999}
            limit={1}
          />

          {/* Providers */}
          <RoomContext.Provider value={roomContext}>

            {/* Routes */}
            <Routes>
              <Route path='/' element={<JoinPage />} />
              <Route path='/auth' element={<AuthPage />} />

              {/* Session */}
              <Route path="/session/join" element={<SessionJoinPage />} />
              <Route path="/session/mixes" element={<SessionMixesPage />} />
              <Route path="/session/settings" element={<SessionSettingsPage />} />

              {/* Mix */}
              <Route path='/mix/home' element={<MixHomePage />} />
              <Route path='/mix/add' element={<MixAddPage />} />
              <Route path='/mix/settings' element={<MixSettingsPage />} />
              <Route path='/mix/setup' element={<MixSetupPage />} />

            </Routes>
          </RoomContext.Provider>
        </MantineProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
