import { Flex, Loader, MantineTheme, Sx } from "@mantine/core";
import ButtonBase from "./ButtonBase";
import { ButtonWithIconProps } from "./_types";
import Text from "../text/FormattedText";
import { TextAlign } from "../text/_types";

export default function ButtonWithIcon(props: ButtonWithIconProps) {

  const flexStyle: Sx = (theme: MantineTheme) => ({
    width: '100%',
    height: '100%',
    gap: theme.spacing.sm,
    justifyContent: props.growText ? null : "center",
    flexDirection: props.iconPosition === "after" ? "row-reverse" : null,
  });

  return (
    <ButtonBase
      {...props}
    >
      <Flex
        align='center'
        sx={flexStyle}
      >
        {!props.loading ? props.icon :
          <Loader
            color={props.color}
            size='sm'
          />
        }

        <Text
          weight={props.weight || 800}
          transform={props.transform}
          align={props.align || TextAlign.Left}
          style={{ flexGrow: props.growText ? 1 : 0 }}
        >
          {props.children}
        </Text>
      </Flex>
    </ButtonBase>
  )
}