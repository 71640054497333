import { Image, ImageProps, MantineTheme, Sx } from "@mantine/core";


export default function InvertableImage(props: ImageProps) {

  const imageStyle: Sx = (theme: MantineTheme) => ({
    filter: theme.colorScheme === 'light' ? null : `invert(100%) grayscale(1)`,
  });



  return (
    <Image
      sx={imageStyle}
      {...props}
    />
  )
}