import { Box, Loader, MantineTheme, Sx, Text } from "@mantine/core";
import { CSSProperties, ReactNode } from "react"
import { ComponentSize } from "../../_phosphorus/_types";
import ButtonBase from "../../_phosphorus/buttons/ButtonBase";

const BUTTON_SIZES = {
  xs: {
    iconWidth: 25,
  },
  sm: {
    iconWidth: 30,
  },
  md: {
    iconWidth: 35,
  },
  lg: {
    iconWidth: 40,
  },
  xl: {
    iconWidth: 50,
  }
}

type Props = {
  icon: ReactNode;
  label: ReactNode;
  direction?: 'reverse' | 'forward';
  size?: ComponentSize,
  onClick?: Function;
  component?: any;
  to?: string;
  disabled?: boolean;
  loading?: boolean;
}

export default function LabelButton(props: Props) {

  /** Styles */
  const buttonStyle: CSSProperties = {
    flexDirection: props.direction === 'reverse' ? 'row-reverse' : 'row',
    padding: 5,
  };
  const iconStyle: Sx = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: props.size ? BUTTON_SIZES[props.size].iconWidth : BUTTON_SIZES.md.iconWidth,
  });
  const labelStyle: Sx = (theme: MantineTheme) => ({
    backgroundColor: theme.black,
    color: theme.white,
    borderRadius: 40,

    height: '100%',
    aspectRatio: '1 / 1',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  return (
    <ButtonBase
      color='black'
      backgroundColor='black'
      radius={ComponentSize.XL}
      style={buttonStyle}
      onClick={props.onClick}
    >
      <Box
        sx={iconStyle}
      >
        {props.icon}
      </Box>

      {/* Label */}
      <Box
        sx={labelStyle}
      >
        {!props.loading ?
          <Text
            size={10}
            weight={800}
          >
            {props.label}
          </Text>
          :
          <Loader
            color='white'
            size='xs'
          />
        }
      </Box>
    </ButtonBase>
  )
}