import { createStyles, Textarea as MantineTextarea } from "@mantine/core";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { ComponentSize } from "../../_phosphorus/_types";

type Props = {
  placeholder: string,
  radius?: ComponentSize;
  size?: ComponentSize;
  icon?: ReactNode;

  minRows?: number;
  autosize?: boolean;
  maxLength?: number;

  value?: string;
  setValue?: Dispatch<SetStateAction<string>>;
}

const useClasses = createStyles((theme) => ({
  textInput: {
    '& > * > .mantine-Input-input': {
      backgroundColor: `${theme.black}20`,
      color: theme.black,
      border: 'none',
      "::placeholder": {
        color: `${theme.black}55`,
      },

      '&:focus': {
        border: `1px solid ${theme.black}`,
      }
    }
  }
}));

export function Textarea(props: Props) {
  const { classes } = useClasses();

  return (
    <MantineTextarea
      variant='filled'
      className={classes.textInput}

      placeholder={props.placeholder}
      radius={props.radius || ComponentSize.MD}
      size={props.size || ComponentSize.MD}
      icon={props.icon}

      minRows={props.minRows || 4}
      autosize={props.autosize}
      maxLength={props.maxLength}

      value={props.value}
      onChange={(event) => props.setValue(event.currentTarget.value)}
    />
  )
}