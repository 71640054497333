import { Accordion, MantineTheme, Stack, Sx } from "@mantine/core";
import MetaRow from "./MetaRow";
import { TrackSmall } from "./Track";
import { Track } from "../../../func/models/spotify/track";
import { Playlist } from "../../../func/models/spotify/playlist";

type Props = {
  playlist: Playlist;
}

export default function PLaylistItem(props: Props) {

  // Styles
  const accordionStyle: Sx = (theme: MantineTheme) => ({
    "& .mantine-Accordion-label": { 
      paddingTop: 5,
      paddingBottom: 5,
    }
  });



  return (
    <Accordion
      variant="filled"
      radius="lg"
      sx={accordionStyle}
    >
      <Accordion.Item value={props.playlist.name}>
        <Accordion.Control>
          <MetaRow
            title={props.playlist.name}
            subtitle={props.playlist.description}
            spotifyLogo={{
              onClick: () => window.open(props.playlist.uri, "_blank")
            }}
          />
        </Accordion.Control>
        <Accordion.Panel>
          <Stack spacing={5}>
            {props.playlist.tracks.map((t: Track) =>
              <TrackSmall
                track={t}
                key={t.id}
                hideSpotifyLogo
                hideImage
              />
            )}
          </Stack>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  )
}