import { createStyles, NumberInput as MantineNumberInput } from "@mantine/core";
import { ReactNode } from "react";
import { ComponentSize } from "../../_phosphorus/_types";

type Props = {
  placeholder: string,
  radius?: ComponentSize,
  size?: ComponentSize,
  icon?: ReactNode,
  
  stepHoldDelay?: number,
  stepHoldInterval?: number,
  max?: number,
  min?: number,
  
  value?: number,
  onChange?(value: number): void
}

const useClasses = createStyles((theme) => ({
  numberInput: {
    '& > * > .mantine-Input-input': {
      backgroundColor: `${theme.black}20`,
      color: theme.black,
      border: 'none',
      "::placeholder": {
        color: `${theme.black}55`,
      },

      '&:hover': {
        backgroundColor: `${theme.black}30`
      },
      '&:focus': {
        border: `1px solid ${theme.black}`,
      },
    },
    '& .mantine-NumberInput-rightSection': {
      width: 'fit-content',
    },
    '& .mantine-NumberInput-rightSection > .mantine-NumberInput-rightSection': {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 10,

      '& .mantine-NumberInput-control': {
        width: 40,
        height: 40,
        border: 'none',
        borderRadius: 50,
      },
      '& .mantine-NumberInput-control:hover': {
        backgroundColor: `${theme.black}30`,
      },
    },
  }
}));

export function NumberInput(props: Props) {
  const { classes } = useClasses();

  return (
    <MantineNumberInput
      variant='filled'
      radius={props.radius || ComponentSize.MD}
      size={props.size || ComponentSize.MD}
      className={classes.numberInput}
      placeholder={props.placeholder}
      icon={props.icon}
      stepHoldDelay={props.stepHoldDelay || 500}
      stepHoldInterval={props.stepHoldInterval || 100}
      max={props.max}
      min={props.min}

      value={props.value}
      onChange={props.onChange}
    />
  )
}