import { Group } from "@mantine/core";
import MetaRow from "./MetaRow";
import Text from "../../_phosphorus/text/FormattedText";
import { IconExplicit, IconTrash } from "@tabler/icons-react";
import { TrackMeta } from "../../../func/models/spotify/track";
import { useContext, useEffect, useState } from "react";
import { RoomContext } from "../../../func/contexts/roomContext";
import { User } from "../../../func/models/user";
import { voteTracks } from "../../../func/websockets/messageSender";
import { ClientSession } from "../../../func/models/sessions/clientSession";
import LabelButton from "../../inputs/buttons/LabelButton";

type Props = {
  track: TrackMeta;
}

export default function QueueItem(props: Props) {

  // Contexts
  const roomContext = useContext(RoomContext);

  // States
  const [guest, setGuest] = useState<User>(null);
  const [hasVoted, setHasVoted] = useState(false);

  /** Vote for or remove an item from the list */
  function trackVote() {
    if (hasVoted) return;
    voteTracks([props.track.uri]);
  }

  useEffect(() => {
    /** Update information about the guest who added this track */
    if (roomContext.party.host.id === props.track.userID) setGuest(roomContext.party.host);
    else {
      setGuest(roomContext.party.guests.find((g) => g.id === props.track.userID));
    }

    /** Check if this session has voted for this track */
    let clientSession = new ClientSession();
    clientSession.deserialise();
    setHasVoted(props.track.votedAgainst.includes(clientSession.id));
  }, [roomContext]);



  return (
    <MetaRow
      title={
        <Group>
          <Text weight={800} lineClamp={1}>
            {props.track.name}
          </Text>
          {props.track.isExplicit ? <IconExplicit size={20} /> : null}
        </Group>
      }
      subtitle={`${props.track.artists.reduce((acc, cur) => acc + cur.name + ', ', '').slice(0, -2)
        } • ${props.track.album.name
        } ${guest ? `• Added by @${guest.username}` : null
        }`}
      rightButtons={
        <LabelButton
          icon={<IconTrash />}
          label={`${props.track.votedAgainst.length}/${roomContext.party.votingPoint}`}
          direction="reverse"
          disabled={hasVoted}
          onClick={() => trackVote()}
        />
      }
      spotifyLogo={{
        onClick: () => window.open(props.track.uri, "_blank")
      }}
    />
  )
}