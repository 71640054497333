import { Center, MantineTheme, Sx } from "@mantine/core";

type Props = {
  totalPages: number;
  currentPage: number;
}

export default function Paginator(props: Props) {

  /** Styles */
  const navStyle: Sx = (theme: MantineTheme) => ({
    height: 40,
  });

  let dots = [];
  for (var i = 0; i < props.totalPages; i++) {
    dots.push(
      <PaginatorDot
        highlighted={i === props.currentPage}
        key={i}
      />
    );
  }

  return (
    <Center
      sx={navStyle}
    >
      {dots}
    </Center>
  )
}

type PDProps = {
  highlighted: boolean;
}

function PaginatorDot(props: PDProps) {
  return (
    <div
      style={{
        height: 10,
        borderRadius: 10,
        width: props.highlighted ? 30 : 10,
        opacity: props.highlighted ? 1 : 0.5,
        margin: 2,
        backgroundColor: 'white',
        transitionDuration: `0.2s`,
      }}
    />
  )
}