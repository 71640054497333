import { Button, Group, Loader, Text } from "@mantine/core";
import { IconLogin } from "@tabler/icons-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, redirect, useNavigate } from "react-router-dom";
import Alert from "../components/display/Alert";
import PWAInstallPrompt from "../components/display/PWAInstallPrompt";
import { TextInput } from "../components/inputs/text/TextInput";
import PageContainer from "../components/layout/PageContainer";
import Title from "../components/text/Title";
import { IAlert } from "../func/interfaces/IAlert";
import { joinRoom } from "../func/models/room";
import { spotifySignIn } from "../func/spotify/spotifyAuth";
import { ClientSession } from "../func/models/sessions/clientSession";
import { SpotifySession } from "../func/models/sessions/spotifySession";
import PasscodeInput from "../components/inputs/text/PasscodeInput";

enum Screen {
  Code,
  Username,
}

type ScreenProps = {
  setScreen: Dispatch<SetStateAction<Screen>>;
  passcode: string;
  setPasscode: Dispatch<SetStateAction<string>>;
}

export default function JoinPage() {

  /** Hooks */
  const navigate = useNavigate();

  /** States */
  const [currentScreen, setCurrentScreen] = useState(Screen.Code);
  const [passcode, setPasscode] = useState('');



  /** Read the party code from the URL parameters */
  function readPartyCode() {
    const params = (new URLSearchParams(window.location.search));
    return params.get('code');
  }

  /** Check if the client has an active SpotifySession, in which
   * case redirect them to the session homepage
   */
  async function detectSession() {
    let spotifySession = await new SpotifySession().deserialise();
    let code = readPartyCode();
    if (spotifySession) navigate(`/session/join${!code ? "" : `?code=${code}`}`);
  }

  /** Attempt to find a code within the URL of a party to connect to */
  function findPartyCode() {
    setPasscode(readPartyCode());
  }

  useEffect(() => {
    detectSession();
    findPartyCode();

    /** Remove the ClientSession from the sessionStorage */
    new ClientSession().remove();
  }, []);



  return (
    <>
      <PageContainer
        fillHeight
      >
        {currentScreen === Screen.Code ?
          <CodeScreen
            setScreen={setCurrentScreen}
            passcode={passcode}
            setPasscode={setPasscode}
          />
          :
          currentScreen === Screen.Username ?
            <UsernameScreen
              setScreen={setCurrentScreen}
              passcode={passcode}
              setPasscode={setPasscode}
            />
            : <></>
        }
      </PageContainer>
    </>
  )
}

function CodeScreen(props: ScreenProps) {

  // Hooks
  const { t } = useTranslation('join');

  /** Called when a valid room is found */
  function roomFound(passcode: string) {
    props.setPasscode(passcode);
    props.setScreen(Screen.Username);
  }



  return (
    <>
      <Title align='center'>
        {t('screens.code.title')}
      </Title>

      {/* Digit input & button */}
      <PasscodeInput onRoomFound={roomFound} />

      {/* Sign in title & button */}
      <Title align='center'>
        {t('screens.code.title2')}
      </Title>

      <Button
        color='black'
        size='md'
        onClick={() => spotifySignIn({ page: "/session/join" })}
      >
        {t('screens.code.buttons.signIn')}
      </Button>

      {/* PWA Install prompt */}
      <PWAInstallPrompt />
    </>
  )
}

function UsernameScreen(props: ScreenProps) {

  // Hooks
  const { t } = useTranslation('join');
  const navigate = useNavigate();

  // States
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<IAlert>(null);



  /** Joins a party, using the provided `uname`, or defaulting to the
   * inputted username
   */
  async function joinParty(uname?: string) {
    uname = uname || username;
    if (loading || !uname) return;

    setLoading(true);
    var response = await joinRoom(props.passcode, uname);
    setLoading(false);

    if (!response) {
      setAlert({
        title: t('alerts.mixNotFound.title'),
        description: t('alerts.mixNotFound.description'),
      });
      return;
    }

    navigate('/mix/home');
  }



  return (
    <>
      <Title align='center'>
        {t('screens.username.title')}
      </Title>

      <Group>
        <TextInput
          placeholder={t('screens.username.inputs.username')}
          style={{ flex: 1 }}
          value={username}
          setValue={setUsername}
          onEnterPress={() => joinParty()}
          autofocus
        />

        <Button
          style={{ height: '100%' }}
          onClick={() => joinParty()}
        >
          {loading ? <Loader size='sm' color='white' /> : <IconLogin />}
        </Button>
      </Group>

      <Text
        align='center'
        size='sm'
        underline
        style={{ cursor: 'pointer' }}
        component={Link}
        to="/"
        onClick={() => props.setScreen(Screen.Code)}
      >
        {t('screens.username.back')}
      </Text>

      {/* Alert */}
      <Alert
        alert={alert}
      />
    </>
  )
}