import { CSSProperties, ReactNode } from "react";
import { ComponentSize } from "../_types";

export enum TextAlign { 
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Justify = 'justify',
}
export enum TextTransform {
  None = "none",
  Capitalize = "capitalize",
  Uppercase = "uppercase",
  Lowercase = "lowercase",
}

export type BaseTextProps = {
  children: ReactNode;
  weight?: number | string;
  align?: TextAlign;
  transform?: TextTransform;

  color?: string;
  size?: number | ComponentSize;
  lineClamp?: number;
  component?: any;

  isMonospace?: boolean;
  style?: CSSProperties;
}