import { Stack } from "@mantine/core"
import { useViewportSize } from "@mantine/hooks"
import { ReactNode } from "react"
import { TALL_BREAKPOINT_PX } from "../_phosphorus/layout/_consts";

type Props = {
  children: ReactNode,
  height?: number,
}

const
  TALL_HEIGHT = 200,
  SHORT_HEIGHT = 100;

export default function Header(props: Props) {

  // Hooks
  const { height } = useViewportSize();
  const isTall = height >= TALL_BREAKPOINT_PX;

  return (
    <Stack
      style={{
        height: props.height || isTall ? TALL_HEIGHT : SHORT_HEIGHT,
        minHeight: props.height || isTall ? TALL_HEIGHT : SHORT_HEIGHT,
        justifyContent: 'center',
      }}
    >
      {props.children}
    </Stack>
  )
}