import { CSSProperties, ReactNode } from "react";
import { ComponentSize } from "../_types";
import { TextAlign, TextTransform } from "../text/_types";

export enum ButtonVariant {
  Filled = 'filled',
  Light = 'light',
  Subtle = 'subtle',
}

export type BaseButtonProps = { 
  /** The style variant of the button. Defaults to subtle */
  variant?: ButtonVariant;
  /** The size of the button. Defaults to the theme default */
  size?: ComponentSize;
  /** The radius of the button. Defaults to the theme default */
  radius?: ComponentSize;
  /** An optional width to supply the button. Accepts any CSS-accepted width property */
  width?: number | string;
  /** An optional height to supply the button. Accepts any CSS-accepted height property */
  height?: number | string;

  /** The background color of this button. Defaults to theme default color */
  backgroundColor?: any;
  /** The content color of this button */
  color?: any;
  
  /** A JS action to take upon clicking the button */
  onClick?: Function;
  /** Polymorphic content to allow this button to become another element */
  component?: any;
  /** Used by react-router-dom */
  to?: string;
  /** Used to link to external sites */
  href?: string;
  
  /** Whether this button is disabled */
  disabled?: boolean;
  /** Whether this button is a square */
  square?: boolean;
  /** Whether this button has a shadow */
  withShadow?: boolean;
  /** Whether this button has motion when hovered and tapped. This value is overridden if the user has reduced motion enabled */
  withMotion?: boolean;

  /** Any child objects of this button */
  children: ReactNode;
  /** Any additional style properties for this button */
  style?: CSSProperties;
}
export type ButtonTextProps = BaseButtonProps & { 
  /** Text weight for this button's text */
  weight?: number;
  /** Text transform property for this button's text */
  transform?: TextTransform;
  /** Text alignment property to apply to this button's text */
  align?: TextAlign;
}
export type ButtonIconProps = BaseButtonProps & {
  /** Whether this icon is loading */
  loading?: boolean;
}
export type ButtonWithIconProps = ButtonTextProps & ButtonIconProps & { 
  /** The icon for this button */
  icon: ReactNode;
  /** The ordering of the icon relative to the text content */
  iconPosition?: "before" | "after";
  /** Whether the text should expand, pushing the icon to a side */
  growText?: boolean;
}