// FLEX LAYOUT
export enum FlexDirection { 
  Vertical = 'column',
  Horizontal = 'row',
  Column = 'column',
  Row = 'row',
  VerticalReverse = 'column-reverse',
  HorizontalReverse = 'row-reverse',
  ColumnReverse = 'column-reverse',
  RowReverse = 'row-reverse',
}
export enum FlexAlign { 
  Start = 'flex-start',
  End = 'flex-end',
  Center = 'center',
  Stretch = 'stretch',
}
export enum FlexJustify { 
  Start = 'flex-start',
  End = 'flex-end',
  Center = 'center',
  Stretch = 'stretch',
  SpaceBetween = 'space-between',
  SpaceAround = 'space-around',
  SpaceEvenly = 'space-evenly',
}

/** Apply this to a parameter to provide an interface for
 * both mobile and desktop variants of an object. Takes a 
 * lot of the load off of high-level elements
 */
export type BreakpointVariant<type> = {
  desktop: type;
  mobile?: type;
}