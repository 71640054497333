import { createContext, Dispatch, SetStateAction } from "react";
import { Mix } from "../models/mix";

export interface IRoomContext { 
  party:        Mix;
  setParty:     Dispatch<SetStateAction<Mix>>;
}

export const RoomContext = createContext<IRoomContext>({
  party:        new Mix('', null, [], '', '', '', 0, false, false, []),
  setParty:     () => {},
})