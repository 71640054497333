import { Accordion, MantineTheme, Stack, Sx } from "@mantine/core";
import { AlbumWithTracks } from "../../../func/models/spotify/album"
import MetaRow from "./MetaRow";
import { TrackSmall } from "./Track";
import { Track } from "../../../func/models/spotify/track";

type Props = {
  album: AlbumWithTracks;
}

export default function AlbumItem(props: Props) {

  // Styles
  const accordionStyle: Sx = (theme: MantineTheme) => ({
    "& .mantine-Accordion-label": { 
      paddingTop: 5,
      paddingBottom: 5,
    }
  });

  

  return (
    <Accordion
      variant="filled"
      radius="lg"
      sx={accordionStyle}
    >
      <Accordion.Item value={props.album.name}>
        <Accordion.Control>
          <MetaRow
            title={props.album.name}
            subtitle={`${props.album.tracks[0].artists.reduce((acc, cur) => acc + cur.name + ', ', '').slice(0, -2)
              } • ${props.album.releaseDate.slice(0, 4)}`}
            image={props.album.thumbnail.url}
            spotifyLogo={{
              onClick: () => window.open(props.album.uri, "_blank")
            }}
          />
        </Accordion.Control>
        <Accordion.Panel>
          <Stack spacing={5}>
            {props.album.tracks.map((t: Track) =>
              <TrackSmall
                track={t}
                key={t.id}
                hideSpotifyLogo
                hideImage
              />
            )}
          </Stack>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  )
}