export class Thumbnail {
  url: string;
  height: number;
  width: number;

  constructor(
    url: string,
    height: number,
    width: number,
  ) {
    this.url = url;
    this.height = height;
    this.width = width;
  }
}