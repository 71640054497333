import { ReactNode } from "react";
import { Title as MantineTitle, TitleOrder } from "@mantine/core";

type Props = { 
  children: ReactNode,
  align?: 'left' | 'right' | 'center' | 'justify',
  order?: TitleOrder;
}

export default function Title(props: Props) { 
  return ( 
    <MantineTitle 
      order={ props.order || 1 }
      align={ props.align }
      color='salmon'
    >
      { props.children }
    </MantineTitle>
  )
}