import { MantineTheme, Sx, Text as BaseText } from "@mantine/core";
import reactStringReplace from "react-string-replace";
import { BaseTextProps } from "./_types";

export default function Text(props: BaseTextProps) {

  let text: any = props.children;

  // Style
  const textStyle: Sx = (theme: MantineTheme) => ({
    fontFamily: props.isMonospace ? theme.fontFamilyMonospace : null,
  });

  /** Run through formatters */
  text = reactStringReplace(text, '<br>', (match, i) => (
    <span key={match + i}>
      <br /><br />
    </span>
  ));
  text = reactStringReplace(text, /<b>(.*?)<\/b>/, (match, i) => (
    <BaseText
      weight={800}
      key={match + i}
      component='span'
      transform={props.transform}
    >
      {match}
    </BaseText>
  ))

  return (
    <BaseText
      weight={props.weight}
      align={props.align}
      size={props.size}
      lineClamp={props.lineClamp}
      transform={props.transform}
      color={props.color}
      component={props.component}

      sx={textStyle}
      style={props.style}
    >
      {text}
    </BaseText>
  )
}