import { Accordion, createStyles, Group, LoadingOverlay, Space, Stack } from "@mantine/core";
import { Icon123, IconAlertTriangle, IconPlaylist, IconRefreshAlert } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Switch from "../../components/inputs/boolean/Switch";
import ButtonIcon from "../../components/_phosphorus/buttons/ButtonIcon";
import { NumberInput } from "../../components/inputs/text/NumberInput";
import { Textarea } from "../../components/inputs/text/Textarea";
import { TextInput } from "../../components/inputs/text/TextInput";
import Header from "../../components/layout/Header";
import InputStack from "../../components/layout/InputStack";
import MixNavbar, { MixPage } from "../../components/layout/nav/MixNavbar";
import PageContainer from "../../components/layout/PageContainer";
import SmallTitle from "../../components/text/SmallTitle";
import Title from "../../components/text/Title";
import { RoomContext } from "../../func/contexts/roomContext";
import { checkMix } from "../../func/models/mix";
import WebsocketMain from "../../func/websockets/websocketMain";
import { clearTracks, deleteRoom, updateParty } from "../../func/websockets/messageSender";
import Modal from "../../components/layout/modals/Modal";
import Button from "../../components/_phosphorus/buttons/Button";
import { ButtonVariant } from "../../components/_phosphorus/buttons/_types";
import { ComponentSize } from "../../components/_phosphorus/_types";
import Text from "../../components/_phosphorus/text/FormattedText";

const useClasses = createStyles((theme) => ({
  buttonGroup: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    paddingTop: theme.spacing.md,
    paddingBottom: theme.spacing.md,
    backgroundColor: theme.white,
    gap: theme.spacing.xs,
  },
}));

export default function MixSettingsPage() {

  /** Contexts */
  const roomContext = useContext(RoomContext);

  /** Hooks */
  const { t } = useTranslation('mixSettings');
  const { classes } = useClasses();
  const navigate = useNavigate();

  /** States */
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [votingPoint, setVotingPoint] = useState(2);
  const [isExplicitAllowed, setIsExplicitAllowed] = useState(true);
  const [isPasswordPublic, setIsPasswordPublic] = useState(true);

  const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] = useState(false);

  /** Set base information */
  function setDefaultInfo() {
    setName(roomContext.party.name);
    setDescription(roomContext.party.description);
    setVotingPoint(roomContext.party.votingPoint);
    setIsExplicitAllowed(roomContext.party.isExplicitAllowed);
    setIsPasswordPublic(roomContext.party.isPasswordPublic);
  }

  function saveParty() {
    setLoading(true);
    updateParty(
      name,
      description,
      votingPoint,
      isExplicitAllowed,
      isPasswordPublic
    );
  }

  function emptyPlaylist() {
    setLoading(true);
    clearTracks()
  }

  function roomDelete() {
    setLoading(true);
    deleteRoom();
  }

  /** Stop loading the page when the roomInfo updates */
  useEffect(() => {
    if (!checkMix(roomContext, navigate)) return;
    if (roomContext.party.id) setLoading(false);
  }, [roomContext]);

  useEffect(() => {
    setDefaultInfo();
  }, [
    roomContext.party.name,
    roomContext.party.description,
    roomContext.party.votingPoint,
    roomContext.party.isExplicitAllowed,
    roomContext.party.isPasswordPublic,
  ])

  return (
    <>
      <WebsocketMain />

      {/* Confirmation modal */}
      <Modal
        opened={isConfirmDeleteModalOpened}
        setOpened={setIsConfirmDeleteModalOpened}
        title={t('dangerZone.inputs.deleteParty.confirmation.title')}
      >
        <Text>
          {t('dangerZone.inputs.deleteParty.confirmation.description')}
        </Text>

        <Group style={{ gap: 5 }}>
          <Button
            color='black'
            style={{ flex: 1 }}
            onClick={() => setIsConfirmDeleteModalOpened(false)}
          >
            {t('dangerZone.inputs.deleteParty.confirmation.no')}
          </Button>
          <Button
            variant={ButtonVariant.Filled}
            style={{ flex: 1 }}
            onClick={() => roomDelete()}
          >
            {t('dangerZone.inputs.deleteParty.confirmation.yes')}
          </Button>
        </Group>
      </Modal>

      <PageContainer
        navbar={
          <MixNavbar
            selectedPage={MixPage.Menu}
          />
        }
      >
        <LoadingOverlay
          visible={loading}
          overlayBlur={2}
        />

        <Header>
          <Title>
            {t('title')}
          </Title>
        </Header>

        <Group
          className={classes.buttonGroup}
        >
          <Space style={{ flex: 1 }} />

          <ButtonIcon
            radius={ComponentSize.MD}
            color='black'
            onClick={() => setDefaultInfo()}
          ><IconRefreshAlert /></ButtonIcon>

          <Button
            variant={ButtonVariant.Filled}
            onClick={() => saveParty()}
          >
            {t('buttons.saveChanges')}
          </Button>
        </Group>

        {/* Basics */}
        <SmallTitle>
          {t('basics.title')}
        </SmallTitle>

        <InputStack>
          <TextInput
            placeholder={t('basics.inputs.name.placeholder')}
            value={name}
            setValue={setName}
          />
          <Textarea
            placeholder={t('basics.inputs.description.placeholder')}
            value={description}
            setValue={setDescription}
            maxLength={500}
          />
        </InputStack>

        <Space h='md' />

        {/* More options */}
        <Accordion
          variant='filled'
          radius='lg'
          defaultValue={'votingPoint'}
        >
          {/* Voting point */}
          <Accordion.Item value='votingPoint'>
            <Accordion.Control>
              <Group>
                <Icon123 />
                <SmallTitle order={4}>
                  {t('votingPoint.title')}
                </SmallTitle>
              </Group>
            </Accordion.Control>

            <Accordion.Panel>
              <InputStack>
                <NumberInput
                  placeholder={t('votingPoint.inputs.votingPoint.placeholder')}
                  min={0}
                  value={votingPoint}
                  onChange={(val) => setVotingPoint(val)}
                />
                <Text
                  size={ComponentSize.SM}
                >
                  {t('votingPoint.inputs.votingPoint.label')}
                </Text>
              </InputStack>
            </Accordion.Panel>
          </Accordion.Item>

          {/* Queue settings */}
          <Accordion.Item value='queueSettings'>
            <Accordion.Control>
              <Group>
                <IconPlaylist />
                <SmallTitle order={4}>
                  {t('queueSettings.title')}
                </SmallTitle>
              </Group>
            </Accordion.Control>

            <Accordion.Panel>
              <InputStack>
                <Switch
                  label={t('queueSettings.inputs.isExplicitAllowed.label')}
                  checked={isExplicitAllowed}
                  setChecked={setIsExplicitAllowed}
                />
                <Text
                  size={ComponentSize.SM}
                >
                  {t('queueSettings.inputs.isExplicitAllowed.description')}
                </Text>
              </InputStack>

              <Space h='md' />

              <InputStack>
                <Switch
                  label={t('queueSettings.inputs.isPasswordPublic.label')}
                  checked={isPasswordPublic}
                  setChecked={setIsPasswordPublic}
                />
                <Text
                  size={ComponentSize.SM}
                >
                  {t('queueSettings.inputs.isPasswordPublic.description')}
                </Text>
              </InputStack>
            </Accordion.Panel>
          </Accordion.Item>

          {/* Danger Zone */}
          <Accordion.Item value='dangerZone'>
            <Accordion.Control>
              <Group>
                <IconAlertTriangle />
                <SmallTitle order={4}>
                  {t('dangerZone.title')}
                </SmallTitle>
              </Group>
            </Accordion.Control>

            <Accordion.Panel>
              <InputStack>
                <Button
                  disabled={roomContext.party.queue.length === 0}
                  onClick={() => emptyPlaylist()}
                >
                  {t('dangerZone.inputs.emptyPlaylist.label')}
                </Button>
                <Text
                  size={ComponentSize.SM}
                >
                  {t('dangerZone.inputs.emptyPlaylist.description')}
                </Text>
              </InputStack>

              <Space h='md' />

              <InputStack>
                <Button
                  variant={ButtonVariant.Filled}
                  onClick={() => setIsConfirmDeleteModalOpened(true)}
                >
                  {t('dangerZone.inputs.deleteParty.label')}
                </Button>
                <Text
                  size={ComponentSize.SM}
                >
                  {t('dangerZone.inputs.deleteParty.description')}
                </Text>
              </InputStack>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

      </PageContainer>
    </>
  )
}