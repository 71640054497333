import { globalSJM } from "../websockets/websocketMain";

export enum MessageEvent { 
  Error,
  AwaitInit,
  InitRoom,
  InitSession,
  RoomUpdate,
  RoomDelete,
  TrackAdd,
  TrackVote,
  TrackRemove,
  PlaylistEmpty,
}

export class Message { 
  event: MessageEvent;
  content: any;
  code?: number;
  
  constructor ( 
    event: MessageEvent,
    content: any,
    code?: number,
  ) { 
    this.event = event;
    this.content = content;
    this.code = code || 200;
  }

  jsonify() { 
    return ({
      event: this.event,
      content: this.content,
      code: this.code,
    });
  }

  send() { 
    globalSJM(this.jsonify());
  }
}

export function parseMessage(messageEvent: any) { 
  let data: Message = JSON.parse(messageEvent.data);

  let message = new Message(
    data.event,
    data.content,
    data.code,
  );
  return message;
}