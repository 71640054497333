import { Stack } from "@mantine/core";
import { ReactNode } from "react";

type Props = { 
  children: ReactNode,
  gap?: number,
}

export default function InputStack(props: Props) { 
  return ( 
    <Stack
      style={{ gap: props.gap || 5 }}
    >
      { props.children }
    </Stack>
  )
}