import { Title, TitleOrder } from "@mantine/core";
import { ReactNode } from "react";

type Props = {
  children: ReactNode,
  order?: TitleOrder,
  align?: 'left' | 'right' | 'center' | 'justify',
  color?: string,
}

export default function SmallTitle(props: Props) {
  return (
    <Title
      order={props.order || 2}
      align={props.align}
      sx={(theme) => ({
        fontWeight: 800
      })}
      color={props.color}
    >
      {props.children}
    </Title>
  )
}