import { API_DOMAIN } from "../../contexts/consts";
import { Album, AlbumWithTracks } from "../spotify/album";
import { Artist } from "../spotify/artist";
import { Playlist } from "../spotify/playlist";
import { Thumbnail } from "../spotify/thumbnail";
import { Track } from "../spotify/track";

/** Similar to a `ClientSession`, a `SpotifySession` represents a
 * persistent session when a user is signed in using Spotify. Unlike
 * a `ClientSession`, `SpotifySession`s are stored in Local Storage,
 * thus are persistent across client sessions.
 * 
 * The information provided by a `SpotifySession` should automatically
 * overwrite the information found in a `ClientSession`.
 */
export class SpotifySession {
  /** The ID of the user. This will always be supplied by Spotify */
  id: string;
  /** A possible display name for the user. If this is null, a fallback
   * should be the user's ID.
   */
  displayName?: string;
  /** The source URL of the user's profile image */
  profileImageURL?: string;
  /** A list of IDs of the user's favourite rooms */
  favouriteRooms?: string[];

  constructor(
    id?: string,
    displayName?: string,
    profileImageURL?: string,
    favouriteRooms?: string[],
  ) {
    this.id = id;
    this.displayName = displayName;
    this.profileImageURL = profileImageURL;
    this.favouriteRooms = favouriteRooms;
  }

  /** Serialise this session to `localStorage` */
  serialise() {
    localStorage.setItem('spotifySession', JSON.stringify(this));
  }

  /** De-serialise this session from the `localStorage` */
  async deserialise() {
    let localSession: SpotifySession = JSON.parse(localStorage.getItem('spotifySession'));
    if (!localSession) return;

    // Refresh all parameters
    this.id = localSession.id;
    this.displayName = localSession.displayName;
    this.profileImageURL = localSession.profileImageURL;
    this.favouriteRooms = localSession.favouriteRooms || [];

    // Retrieve information from the server & use that to update the local copy
    let onlineSession = await this.get();
    if (!onlineSession) return this;
    
    // Refresh all parameters & serialise
    this.displayName = onlineSession.displayName;
    this.profileImageURL = onlineSession.profileImageURL;
    this.favouriteRooms = onlineSession.favouriteRooms;
    this.serialise();

    return this;
  }

  /** Remove this session from the `localStorage` */
  remove() {
    localStorage.removeItem('spotifySession');
  }

  /** Checks if this session exists within the `localStorage`.
   * @returns `true` if the session exists and is valid; `false` otherwise
   */
  static exists() {
    let session = new SpotifySession();
    session.deserialise();

    if (session.id) return true;
    return false;
  }



  // ONLINE SYNCHRONISATION
  /** Registers/"creates" this user on the Realmix servers so we can add
   * information to it.
   */
  async create(refreshToken: string) {
    var response = await fetch(`${API_DOMAIN}user/`, {
      method: "PUT",
      body: JSON.stringify({
        id: this.id,
        displayName: this.displayName,
        profileImageURL: this.profileImageURL,
        refreshToken: refreshToken,
        favouriteRooms: [],
      }),
    });
    if (!response.ok) console.log("Failed to create user", response.status);
  }

  /** Retrieve basic information about this user. This will naturally exclude
   * their refreshToken
   */
  async get() {
    var response = await fetch(`${API_DOMAIN}user?id=${this.id}`);
    if (!response.ok) {
      console.log("Failed to find user", response.status);
      return;
    }

    let info = await response.json();
    return new SpotifySession(
      info.id,
      info.displayName,
      info.profileImageURL,
      info.favouriteRooms,
    );
  }

  /** Permanently deletes all information saved about the user from the Realmix
   * server
   */
  async delete() {
    var response = await fetch(`${API_DOMAIN}user/`, {
      method: "DELETE",
    });
    if (!response.ok) console.log("Failed to delete user", response.status);
  }

  /** Synchronise the local and remote copy of the spotifySession */
  async sync() { 
    // Retrieve information from the server & use that to update the local copy
    let onlineSession = await this.get();
    if (!onlineSession) return this;
    
    // Refresh all parameters & serialise
    this.displayName = onlineSession.displayName;
    this.profileImageURL = onlineSession.profileImageURL;
    this.favouriteRooms = onlineSession.favouriteRooms;
    this.serialise();
  }

  /** Retrieve this user's saved tracks. This is a list of Tracks that would 
   * appear in the "Liked Songs" playlist in the regular Spotify GUI.
   */
  async getSavedTracks(limit?: number) {
    var response = await fetch(`${API_DOMAIN}user/tracks?userID=${this.id}&limit=${limit || 10}`);
    if (!response.ok) {
      console.log("Failed to retrieve user tracks", response.status);
      return;
    }

    let info: Track[] = await response.json();
    return info.map(t => new Track(
      t.id,
      t.name,
      t.uri,
      new Album(
        t.album.id,
        t.album.name,
        t.album.uri,
        t.album.releaseDate,
        new Thumbnail(
          t.album.thumbnail.url,
          t.album.thumbnail.height,
          t.album.thumbnail.width,
        ),
      ),
      t.artists.map(a => new Artist(
        a.id,
        a.name,
        a.uri,
      )),
      t.duration,
      t.isExplicit,
    ));
  }

  /** Retrieve this user's saved albums. This is a list of albums the user has
   * liked, and each album contains all of the songs therein thanks the the
   * special `AlbumWithTracks` extension class
   */
  async getSavedAlbums(limit?: number) {
    var response = await fetch(`${API_DOMAIN}user/albums?userID=${this.id}&limit=${limit || 10}`);
    if (!response.ok) {
      console.log("Failed to retrieve user albums", response.status);
      return;
    }

    let info: AlbumWithTracks[] = await response.json();
    return info.map(a => new AlbumWithTracks(
      a.id,
      a.name,
      a.uri,
      a.releaseDate,
      new Thumbnail(
        a.thumbnail.url,
        a.thumbnail.height,
        a.thumbnail.width,
      ),
      a.tracks.map(t => new Track(
        t.id,
        t.name,
        t.uri,
        new Album(
          t.album.id,
          t.album.name,
          t.album.uri,
          t.album.releaseDate,
          new Thumbnail(
            t.album.thumbnail.url,
            t.album.thumbnail.height,
            t.album.thumbnail.width,
          ),
        ),
        t.artists.map(a => new Artist(
          a.id,
          a.name,
          a.uri,
        )),
        t.duration,
        t.isExplicit,
      )),
    ));
  }

  /** Retrieve this user's saved playlists. This a list of all the playlists
   * the user has created, each of which contains all the songs therein
   */
  async getSavedPlaylists(limit?: number) {
    var response = await fetch(`${API_DOMAIN}user/playlists?userID=${this.id}&limit=${limit || 10}`);
    if (!response.ok) {
      console.log("Failed to retrieve user playlists", response.status);
      return;
    }

    let info: Playlist[] = await response.json();
    return info.map(p => new Playlist(
      p.id,
      p.name,
      p.description,
      p.uri,
      p.tracks.map(t => new Track(
        t.id,
        t.name,
        t.uri,
        new Album(
          t.album.id,
          t.album.name,
          t.album.uri,
          t.album.releaseDate,
          new Thumbnail(
            t.album.thumbnail.url,
            t.album.thumbnail.height,
            t.album.thumbnail.width,
          ),
        ),
        t.artists.map(a => new Artist(
          a.id,
          a.name,
          a.uri,
        )),
        t.duration,
        t.isExplicit,
      )),
    ));
  }



  // ASSISTANCE FUNCTIONS
  /** Check if this user is registered on the Realmix worker */
  async existsOnWorker() { 
    let session = await this.get();
    return session != null;
  }



  // FAVOURITE ROOMS

  /** Add a number of rooms to the user's list of favourites */
  async addFavouriteRooms(rooms: string[]) {
    var response = await fetch(`${API_DOMAIN}user/favourite-room?userID=${this.id}`, {
      method: "PUT",
      body: JSON.stringify({
        rooms: rooms.map(r => { return { id: r } }),
      }),
    });
    if (!response.ok) console.log("Failed to add favourite rooms", response.status);

    await this.sync();
  }

  /** Remove a room from the user's list of favourites */
  async removeFavouriteRoom(room: string) {
    var response = await fetch(`${API_DOMAIN}user/favourite-room?userID=${this.id}&roomID=${room}`, {
      method: "DELETE",
    });
    if (!response.ok) console.log("Failed to remove favourite room", response.status);

    await this.sync();
  }
}

