import { Message, MessageEvent } from "../models/message";
import { Mix } from "../models/mix";

/** Updates the party, sending it through the websocket.
 * Only supplied values will be updated by the server
 */
export function updateParty(
  name?: string,
  description?: string,
  votingPoint?: number,
  isExplicitAllowed?: boolean,
  isPasswordPublic?: boolean,
) { 
  (new Message(
    MessageEvent.RoomUpdate,
    { 
      party: new Mix(
        null,
        null,
        null,
        name,
        description,
        null,
        votingPoint,
        isExplicitAllowed,
        isPasswordPublic,
        null
      ),
    },
    200,
  )).send();
}

/** Add tracks to the party */
export function addTracks(uris: string[], insertAtTop: boolean) { 
  (new Message(
    MessageEvent.TrackAdd,
    { 
      uris: uris,
      insertAtTop: insertAtTop,
    },
    200,
  )).send();
}

/** Remove tracks from the party */
export function voteTracks(uris: string[]) { 
  (new Message(
    MessageEvent.TrackVote,
    { 
      uris: uris,
    },
    200,
  )).send();
}

/** Clears all tracks from the party */
export function clearTracks() { 
  (new Message(
    MessageEvent.PlaylistEmpty,
    {},
    200,
  )).send();
}

export function deleteRoom() { 
  (new Message(
    MessageEvent.RoomDelete,
    {},
    200,
  )).send();
}