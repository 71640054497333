import Text from "../text/FormattedText";
import { TextAlign } from "../text/_types";
import ButtonBase from "./ButtonBase";
import { ButtonTextProps } from "./_types";

export default function Button(props: ButtonTextProps) {
  return (
    <ButtonBase
      { ...props }
    >
      <Text
        weight={props.weight || 800}
        transform={props.transform}
        align={props.align || TextAlign.Center}
      >
        {props.children}
      </Text>
    </ButtonBase>
  )
}