import Logo from "../../../assets/images/spotify-logo.png";

import { Image } from "@mantine/core";
import { MantineTheme, Sx } from "@mantine/styles";
import { SpotifyLogoProps } from "./_types";

export default function SpotifyLogo(props: SpotifyLogoProps) {

  // Styles
  const imageStyle: Sx = (theme: MantineTheme) => ({
    filter: props.followTheme ?
      theme.colorScheme === "light" ?
        "grayscale(1)" : "invert(100%) grayscale(1)"
      : props.default === "dark" ?
        "invert(100%) grayscale(1)" : "grayscale(1)",
    cursor: props.onClick ? "pointer" : null,
    zIndex: 0,
  });



  return (
    <>
      <Image
        src={Logo}
        width={25}
        sx={imageStyle}
        onClick={props.onClick ? (event: any) => {
          event.stopPropagation();
          props.onClick();
        } : null}
      />
    </>
  )
}