import { createStyles, Modal } from "@mantine/core";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { ComponentSize } from "../../_phosphorus/_types";
import { BLACK_PALETTE } from "../../_colors";

type Props = {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;

  children: ReactNode;

  overlayBlur?: number;
  overlayOpacity?: number;

  size?: ComponentSize;
}

export default function GlassModal(props: Props) {
  const useClasses = createStyles(() => ({
    modal: {
      zIndex: 1000,

      '& .mantine-Paper-root': {
        background: 'none',
        boxShadow: 'none',
      }
    }
  }));

  const { classes } = useClasses();

  return (
    <Modal
      opened={props.opened}
      onClose={() => props.setOpened(false)}

      className={classes.modal}

      overlayProps={{
        color: BLACK_PALETTE[0],
        opacity: props.overlayOpacity || 0.6,
        blur: props.overlayBlur || 3
      }}

      centered={true}
      lockScroll={true}
      closeOnClickOutside={true}
      closeOnEscape={true}
      withCloseButton={false}
    >
      {props.children}
    </Modal>
  )
}