/** This is a class that stores information about this 
 * client's session. This will serialise directly to 
 * sessionStorage so that it's persistent, even if 
 * the user reloads their page or is idle for a period.
 * 
 * ClientSessions can be constructed without any values,
 * just use `deserialise()` to retrieve all values from 
 * storage.
 */
export class ClientSession { 
  /** The ID of the user. This may be supplied by Spotify, 
   * or generated by us.
   */
  id: string;
  /** The chosen name of this user. For signed-in users, 
   * this will be their Spotify ID as well.
   */
  username: string;
  /** The ID/name of the room this user is connected to */
  roomID: string;
  /** Whether this user is the host of the room */
  isHost: boolean;

  constructor ( 
    id?: string,
    username?: string,
    roomID?: string,
    isHost?: boolean,
  ) { 
    this.id = id;
    this.username = username;
    this.roomID = roomID;
    this.isHost = isHost;
  }

  /** Serialise this session to the `sessionStorage` */
  serialise() { 
    sessionStorage.setItem('clientSession', JSON.stringify(this));
  }

  /** De-serialise this session from the `sessionStorage` */
  deserialise() { 
    let clientSession: ClientSession = JSON.parse(sessionStorage.getItem('clientSession'));
    if (!clientSession) return;
    
    // Refresh all parameters
    this.id = clientSession.id;
    this.username = clientSession.username;
    this.roomID = clientSession.roomID;
    this.isHost = clientSession.isHost;

    return this;
  }

  /** Remove this session from the `sessionStorage` */
  remove() { 
    sessionStorage.removeItem('clientSession');
  }

    /** Checks if this session exists within the `sessionStorage`.
   * @returns `true` if the session exists and is valid; `false` otherwise
   */
    static exists() { 
      let session = new ClientSession();
      session.deserialise();
  
      if (session.id) return true;
      return false;
    }
}