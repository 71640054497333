import { createStyles, TextInput as MantineTextInput } from "@mantine/core";
import { CSSProperties, Dispatch, ReactNode, SetStateAction } from "react";
import { ComponentSize } from "../../_phosphorus/_types";

type Props = {
  placeholder: string,
  radius?: ComponentSize;
  size?: ComponentSize;
  icon?: ReactNode;

  value?: string;
  setValue?: Dispatch<SetStateAction<string>>;

  onEnterPress?: Function;
  autofocus?: boolean;

  style?: CSSProperties;
}

const useClasses = createStyles((theme) => ({
  textInput: {
    '& > * > .mantine-Input-input': {
      backgroundColor: `${theme.black}20`,
      color: theme.black,
      border: 'none',
      "::placeholder": {
        color: `${theme.black}55`,
      },

      '&:focus': {
        border: `1px solid ${theme.black}`,
      }
    }
  }
}));


export function TextInput(props: Props) {
  const { classes } = useClasses();


  const handleKeyDown = (event: any) => {
    if (props.onEnterPress && event.key === 'Enter') props.onEnterPress();
  }

  return (
    <MantineTextInput
      variant='filled'
      className={classes.textInput}

      placeholder={props.placeholder}
      radius={props.radius || ComponentSize.MD}
      size={props.size || ComponentSize.MD}
      icon={props.icon}

      value={props.value}
      onChange={(event) => props.setValue(event.currentTarget.value)}
      onKeyDown={handleKeyDown}
      style={props.style}
      autoFocus={props.autofocus}
    />
  )
}