import { IAlert } from "../../func/interfaces/IAlert";
import { Alert as MantineAlert } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { CSSProperties } from "react";
import { WHITE_PALETTE } from "../_colors";
import Text from "../_phosphorus/text/FormattedText";

type Props = {
  alert: IAlert,
}

export default function Alert(props: Props) {

  const textStyle: CSSProperties = {
    color: WHITE_PALETTE[0],
  }

  return (
    props.alert ?
      <MantineAlert
        icon={props.alert.icon || <IconAlertCircle size={16} />}
        variant='filled'
        radius='md'
        style={textStyle}
        color="black"
      >
        <Text
          weight={800}
          size={16}
        >
          {props.alert.title}
        </Text>
        <Text>
          {props.alert.description}
        </Text>
      </MantineAlert>
      : null
  )
}